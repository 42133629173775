import readNotifications from '../services/micro/read_notifications'
import { toast } from 'react-toastify'
export const onReadNotification = (id, link, notificationPath) => {
    readNotifications([id])
        .then(_res => {
            if (link) {
                window.location.replace(link)
            } else {
                window.location.href = notificationPath
            }
        })
        .catch(() => {
            toast.error('Server error')
        })
}
