import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function ClicksCellRenderer({ value }) {
    return <div className="align-items-center">{value}</div>;
}

export function YourClicksCellColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_branding_column_headers.your_clicks;
}

export function YourClicksCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    if (value === "-") {
        return "";
    }
    
    return taxonomy.tooltips.ppc_branding_column_inlines.your_clicks.replace("{clicks}", value);
}
 