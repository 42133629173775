import { Col } from "react-bootstrap";

export default function NoActiveBidsAlert({
    isNoBidsFlag,
    closedAlert,
    setClosedAlert,
}) {
    return (
        isNoBidsFlag ? (
            !closedAlert ? (
              <Col md="12">
                <div className="fade alert alert-dismissible danger-system-alert show mb-0">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close alert"
                    onClick={() => setClosedAlert(true)}
                  ></button>
                  <div className="banner">
                    <h3 className="banner-title">
                      You don't have any active bids at the moment.
                    </h3>
                    <p className="banner-text">
                      Please activate some bids to start bidding on PPC
                      inventory.
                    </p>
                  </div>
                </div>
              </Col>
            ) : (
              <></>
            )
          ) : (
            <></>
          )
    )
}