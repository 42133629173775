import axios from 'axios'
import { getToken } from '../../helpers/get-token'
import { endOfDay, startOfDay } from 'date-fns'
import { normalizeToUTC } from '../../helpers/normalize-to-utc'

async function getPeriodRevenue(currentPeriod, previousPeriod) {
    try {
        const payload = {
            current_period: [normalizeToUTC(currentPeriod[0]), normalizeToUTC(currentPeriod[1])],
            previous_period: [normalizeToUTC(previousPeriod[0]), normalizeToUTC(previousPeriod[1])]
        }
        console.log('payload: ', payload)
        const token = getToken()
        axios.defaults.headers.common['authorization'] = token
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/revenue/period', {
            // current_period: [startOfDay(normalizeToUTC(currentPeriod[0])), endOfDay(normalizeToUTC(currentPeriod[1]))],
            // previous_period: [startOfDay(normalizeToUTC(previousPeriod[0])), endOfDay(normalizeToUTC(previousPeriod[1]))]
            // current_period: [startOfDay(currentPeriod[0]), endOfDay(currentPeriod[1])],
            // previous_period: [startOfDay(previousPeriod[0]), endOfDay(previousPeriod[1])]
            // current_period: [normalizeToUTC(currentPeriod[0]), normalizeToUTC(currentPeriod[1])],
            // previous_period: [normalizeToUTC(previousPeriod[0]), normalizeToUTC(previousPeriod[1])]
            // current_period: [currentPeriod[0], currentPeriod[1]],
            // previous_period: [previousPeriod[0], previousPeriod[1]]
            ...payload
            
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export default getPeriodRevenue
