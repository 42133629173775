import { Form, Card, Button, Col, Row, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { CookiesKeyNames, useCookiesContext } from '../contexts/CookiesContext'
import { GetPubAbbr } from '../helpers/get-pub-abbr'
import { handleFormSubmit } from '../helpers/handle-signup-submit'
import { logosModule } from '../modules/logos'
import { getPublisherCategories } from '../helpers/get-publisher-categories'
import { loadersModule } from '../modules/loaders'
import { toast } from 'react-toastify'
import validateDisposableEmail from '../services/micro/validate_disposable_email'

const validateEmail = async email => {
    const redurantEmails = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'msn', 'live', 'zoho', 'aol', 'yandex', 'mail', 'inbox']
    const emailDomain = email.split('@')[1]
    if (redurantEmails.includes(emailDomain)) {
        toast.error('Invalid email. Please use a valid email address.')
        return
    }
    const disposableEmail = await validateDisposableEmail(email)
    if (disposableEmail.isDisposableEmail) {
        toast.error('Invalid email. Please use your company email')
        return
    }
}

export const Onboarding = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [productName, setProductName] = useState('')
    const [productUrl, setProductUrl] = useState('')
    const [category, setCategory] = useState(null)
    const [categories, setCategories] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalStatus, setModalStatus] = useState(null)
    const [modalMessage, setModalMessage] = useState('')
    const [siteLogo, setSiteLogo] = useState(null)
    const [publisherId, setPublisherId] = useState(null)

    const { cookiesData, getPathKey, updateCookiesProperty } = useCookiesContext()

    const cookiesDataByPath = cookiesData[getPathKey()]

    const token = cookiesDataByPath?.[CookiesKeyNames.token]

    useEffect(() => {
        const skinMode = cookiesDataByPath?.[CookiesKeyNames.skinMode]
        if (skinMode) {
            updateCookiesProperty(CookiesKeyNames.skinMode, skinMode)
        }
    }, [])

    useEffect(() => {
        const publisherIdByAbbr = GetPubAbbr()
        console.log('publisherIdByAbbr', publisherIdByAbbr)
        let siteLogo = logosModule(publisherIdByAbbr)

        if (!publisherIdByAbbr) {
            const publisherIdFromCookies = cookiesData['pub']?.[CookiesKeyNames.publisherId]

            console.log('publisherIdFromCookies', publisherIdFromCookies)
            siteLogo = logosModule(publisherIdFromCookies)
        }

        setSiteLogo(siteLogo)
        setPublisherId(publisherIdByAbbr)
    }, [cookiesData, publisherId])

    useEffect(() => {
        const fetchCategories = async () => {
            const result = await getPublisherCategories(token, publisherId)

            if (result) {
                const formatedCategories = result.data.map(category => ({
                    label: category.name,
                    value: category.id
                }))
                setCategories(formatedCategories)
            }
        }

        fetchCategories()
    }, [publisherId, token])

    const onSubmit = e => {
        e.preventDefault()
        const formData = {
            first_name: firstName,
            last_name: lastName,
            email,
            company_name: companyName,
            product_name: productName,
            product_url: productUrl,
            main_category: category?.value,
            publisher_id: publisherId
        }
        handleFormSubmit({
            formData,
            setIsLoading,
            setIsModalOpen,
            setModalMessage,
            setModalStatus
        })
    }

    return (
        <Form className="page-sign" onSubmit={onSubmit}>
            {isModalOpen && (
                <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                    <Modal.Header>
                        <Modal.Title>{modalStatus === 'accepted' ? 'Application accepted' : 'Application submitted'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p dangerouslySetInnerHTML={{ __html: modalMessage }} />
                    </Modal.Body>
                </Modal>
            )}
            {isLoading && (
                <div
                    className="page-sign-loader-container"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                        backdropFilter: 'blur(5px)'
                    }}
                >
                    <div className="page-sign-loader">{loadersModule(Number(publisherId))}</div>
                    <div className="page-sign-loader-text">
                        We're processing your request. This may take a minute or so.
                    </div>
                </div>
            )}
            <Card className="card-sign" style={{ width: '620px' }}>
                <Card.Header>
                    <Link to="/" className="header-logo">
                        {siteLogo}
                    </Link>
                    <Card.Title className="mb-4">Advertiser Sign Up</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        <Col md={6} className="p-1">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                onChange={e => {
                                    setFirstName(e.target.value)
                                }}
                                value={firstName}
                                type="text"
                                placeholder="Enter your first name*"
                            />
                        </Col>
                        <Col md={6} className="p-1">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                onChange={e => {
                                    setLastName(e.target.value)
                                }}
                                value={lastName}
                                type="text"
                                placeholder="Enter your last name*"
                            />
                        </Col>
                        <Col md={6} className="p-1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                onChange={e => {
                                    setEmail(e.target.value)
                                    validateEmail(e.target.value)
                                }}
                                value={email}
                                type="email"
                                placeholder="Enter your email address*"
                            />
                        </Col>
                        <Col md={6} className="p-1">
                            <div className="col">
                                <small
                                    className="mt-4 onboarding-border"
                                    style={{
                                        borderLeft: 'solid 3px',
                                        padding: '5px 7px'
                                    }}
                                >
                                    Please use your company email, so we can verify that your company owns the product.
                                </small>
                            </div>
                        </Col>
                        <hr style={{ marginBottom: 0 }} />
                        <Col md={12} className="p-1" style={{ marginTop: '0.25rem' }}>
                            <Form.Label>Company name</Form.Label>
                            <Form.Control
                                onChange={e => {
                                    setCompanyName(e.target.value)
                                }}
                                value={companyName}
                                type="text"
                                placeholder="Enter your company name*"
                            />
                        </Col>
                        <hr style={{ marginBottom: 0 }} />
                        <Col md={6} className="p-1" style={{ marginTop: '0.25rem' }}>
                            <Form.Label>Product name</Form.Label>
                            <Form.Control
                                onChange={e => {
                                    setProductName(e.target.value)
                                }}
                                value={productName}
                                type="text"
                                placeholder="Enter your product name*"
                            />
                        </Col>
                        <Col md={6} className="p-1" style={{ marginTop: '0.25rem' }}>
                            <Form.Label>Product url</Form.Label>
                            <Form.Control
                                onChange={e => {
                                    setProductUrl(e.target.value)
                                }}
                                value={productUrl}
                                type="text"
                                placeholder="Enter your product url*"
                            />
                        </Col>
                        <Col md={6} className="p-1" style={{ marginTop: '0.25rem' }}>
                            <Form.Label>Main category</Form.Label>

                            <Select
                                className="custom-select"
                                id="basic-select-single"
                                value={category}
                                placeholder={'Pick your category*'}
                                options={categories}
                                onChange={selectedOption => setCategory(selectedOption)}
                            />
                        </Col>
                        <Col md={6} className="p-1" style={{ marginTop: '0.25rem' }}>
                            <div className="col">
                                <small
                                    className="mt-4 onboarding-border"
                                    style={{
                                        borderLeft: 'solid 3px',
                                        padding: '5px 7px'
                                    }}
                                >
                                    Pick the category that best describes your product. You can add more later.
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-2 mb-3">
                        <small>
                            By applying for access below, you agree to our{' '}
                            <a href="/" target="_blank">
                                terms of service
                            </a>{' '}
                            and{' '}
                            <a href="/" target="_blank">
                                privacy policy
                            </a>
                            . The application review is AI powered - you will get an immediate answer if your product is elegible.
                        </small>
                    </div>
                    <hr />
                    <Button type="submit" variant="primary" className="btn-sign">
                        Apply for access
                    </Button>
                </Card.Body>
                <Card.Footer style={{ padding: '35px' }}>
                    Already have an account? <a href="/pages/signin">Sign In</a> instead.
                </Card.Footer>
            </Card>
        </Form>
    )
}
