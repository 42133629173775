import Cookies from "universal-cookie";
import { CookiesKeyNames, isCookiesEnabled } from "../contexts/CookiesContext";
import { abbreviationsMap } from "../modules/maps";

export function GetPubAbbr() {
  const urlParams = new URLSearchParams(window.location.search);
  const pubParam = urlParams.get("pub");
  const cookies = new Cookies();
  const publisher_id = isCookiesEnabled
    ? cookies.get("pub")?.[CookiesKeyNames.publisherId]
    : localStorage.getItem("pub")?.[CookiesKeyNames.publisherId];
  const urlDomain = window.location.hostname;

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (urlDomain === "app.netnetix.com") {
      return 34;
  }

  if (urlDomain === "stage.netnetix.com") {
    return 34;
  }
  
  if (
    pubParam !== null &&
    pubParam !== undefined &&
    pubParam !== "" &&
    pubParam !== "undefined" &&
    pubParam !== "null"
  ) {
    if (Number(pubParam)) {
      return Number(pubParam);
    }

    return Number(abbreviationsMap[pubParam]);
  }
  
  return Number(publisher_id);
}
