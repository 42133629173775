import CreditsLackAlert from "./CreditsLackAlert";
import InactiveCampaignAlert from "./InactiveCampaignAlert";
import NoActiveBidsAlert from "./NoActiveBidsAlert";
import NotShownInheritedAuctionsAlert from "./NotShownInheritedAuctionsAlert";
import PausedCampaignAlert from "./PausedCampaignAlert";

export default function AdvertiserPPCAlerts({
    isCreditBalanceSmallerThanTopBid,
    isCampaignInactive,
    isCampaignPaused,
    clusterInheritedAuctionsAndNotShown,
    isNoBidsFlag,
    closedAlert,
    setClosedAlert,
    isHTR,
}) {
    return (
        <>
            <CreditsLackAlert
              isCreditBalanceSmallerThanTopBid={isCreditBalanceSmallerThanTopBid}
              closedAlert={closedAlert}
              setClosedAlert={setClosedAlert}
            />
            <InactiveCampaignAlert
              isCampaignInactive={isCampaignInactive}
              isHTR={isHTR}
              closedAlert={closedAlert}
              setClosedAlert={setClosedAlert}
            />
            <PausedCampaignAlert
              isCampaignPaused={isCampaignPaused}
              closedAlert={closedAlert}
              setClosedAlert={setClosedAlert}
            />
            <NotShownInheritedAuctionsAlert
              clusterInheritedAuctionsAndNotShown={clusterInheritedAuctionsAndNotShown}
              closedAlert={closedAlert}
              setClosedAlert={setClosedAlert}
            />
            <NoActiveBidsAlert
              isNoBidsFlag={isNoBidsFlag}
              closedAlert={closedAlert}
              setClosedAlert={setClosedAlert}
            />
        </>
    )
}