import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Placeholder } from "react-bootstrap";

import { getCSSVariableValue } from "../../../../../helpers/get-css-variable-value";
import getPeriodRevenueByCluster from "../../../../../services/micro/get_period_revenue_by_cluster";

function formatDateRange(datesArray) {
  const startDate = new Date(datesArray[0][0]);
  const endDate = new Date(datesArray[datesArray.length - 1][0]);

  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedStartDate = startDate.toLocaleDateString('en-GB', options).replace(/ /g, '. ');
  const formattedEndDate = endDate.toLocaleDateString('en-GB', options).replace(/ /g, '. ');

  return `Date range - ${formattedStartDate} - ${formattedEndDate}`;
}

export default function ClusterClicksViews({ inventoryId, campaignId }) {
  const [colors, setColors] = useState({ chartPrimary: '', chartSecondary: '' });
  const [data, setData] = useState();

  useEffect(() => {
    setColors({
      chartPrimary: getCSSVariableValue('--chart-color-1'),
      chartSecondary: getCSSVariableValue('--chart-color-2')
    });
  }, []);

  useEffect(() => {
    getPeriodRevenueByCluster(inventoryId, campaignId).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [inventoryId, campaignId]);

  const options = {
    chart: {
      toolbar: { show: false },
      stacked: false,
      zoom: { enabled: false },
      offsetX: -4,
      offsetY: 15,
    },
    dataLabels: { enabled: false },
    colors: [colors.chartPrimary, colors.chartSecondary],
    legend: { show: false },
    stroke: {
      curve: 'smooth',
      width: [0, 2],
    },
    fill: {
      type: ["solid", "gradient"],
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.35,
        opacityTo: 0.2,
        stops: [0, 100],
        gradientToColors: ["#f3f5fc"],
      },
    },
    grid: {
      show: false,
      borderColor: "rgba(72,94,144, 0.07)",
      padding: { left: -38, right: -10, bottom: -30 },
    },
    xaxis: {
      type: "datetime",
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    tooltip: { enabled: false },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  };


  const series = [
    {
      name: "Clicks",
      type: "column",
      data: data ? data.periods.find((period) => period.name === "Clicks").data : [],
    },
    {
      name: "Views",
      type: "area",
      data: data ? data.periods.find((period) => period.name === "Views").data : [],
    },
  ];


  if (data) {
    const maxClicks = Math.max(...data.periods.find((period) => period.name === "Clicks").data.map((item) => item[1]));
    const maxViews = Math.max(...data.periods.find((period) => period.name === "Views").data.map((item) => item[1]));


    const reducedMaxClicks = maxClicks * 1.4;
    const reducedMaxViews = maxViews * 1;
    
    options.yaxis= [
      {
        labels: { show: false },
        lines: { show: false },
        max: reducedMaxClicks,
      },
      {
        labels: { show: false },
        lines: { show: false },
        max: reducedMaxViews,
      },
    ]

    return (
      <React.Fragment>
        <Card className="card-one">
          <Card.Body className="overflow-hidden px-1 pb-0" >
            <div className="finance-info p-3 p-xl-4">
              <label className="chart-date-range">
                {formatDateRange(data.periods[0].data)}
              </label> <br />
              <label className="fs-sm fw-medium mb-2 mb-xl-1 subtext">
                Clicks and views across the entire cluster over the last 30 days
              </label>
            </div>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={215}
              width={"102%"}
            />
            <div className="chart-labels">
              <label className="fs-sm fw-medium text-secondary fw-bold">
                {data.total_clicks} Total Clicks
              </label>
              <label className="fs-sm fw-medium ms-4 text-primary fw-bold">
                {data.total_views} Views
              </label>
            </div>

          </Card.Body>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
          <Placeholder style={{ height: 240, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
