import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function createPaymentIntent(values) {
  try {
    const token = getToken();
    axios.defaults.headers.common["authorization"] = token;
    const response = await axios.post(
      process.env.REACT_APP_HUB_URL + "/api/stripe/create-payment-intent",
      values
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export default createPaymentIntent;
