import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { formatThousand } from "../../../../../helpers/thousand-number-format";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function ClusterYourBidCellRenderer({ value }) {
    const your_bid = value.split("@")[0];
    const inherited_cpc = value.split("@")[1];
    const cluster_inherited_bid_mode_enabled = value.split("@")[2];
    const status = value.split("@")[3].toUpperCase();

    if (value === "-") {
        return "No bid";
    }

    if (status === "INACTIVE" || status === "DRAFT") {
        return (
            <div>
                <span className="text-secondary">{your_bid}</span>
            </div>
        )
    }

    if (cluster_inherited_bid_mode_enabled == "true") {
        if (status === "BID ACTIVE") {
            return (
                <div>
                    <span className="">{your_bid}</span>
                </div>
            )
        } else if (status === "INHERITED") {
            const cpc = inherited_cpc === "null" ? your_bid : '$' + Number(inherited_cpc).toFixed(2);
            return ( 
                <div>
                    <span className="text-secondary">{cpc}</span>
                </div>
            )
        } else {
            return (
                <div>
                    <span className="text-secondary">{your_bid}</span>
                </div>
            )
        }
    } else {
        return (
            <div>
                <span className="text-secondary">{your_bid}</span>
            </div>
        )
    }
}

export function ClusterYourBidCellColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_cluster_column_headers.your_bid;
}

export function ClusterYourBidCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    const your_bid = value.split("@")[0];
    const inherited_cpc = value.split("@")[1];
    const cluster_inherited_bid_mode_enabled = value.split("@")[2];
    const status = value.split("@")[3].toUpperCase();

    if (value === "-") {
        return taxonomy.tooltips.ppc_cluster_column_inlines.your_bid.no_bid;
        // return "Your bid is not set or campaign is paused or not active";
    }

    if (status === "INACTIVE" || status === "DRAFT") {
        let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.your_bid.no_bid;
        return tooltip;
        // return "Your bid is " + your_bid;
    }

    if (cluster_inherited_bid_mode_enabled == "true") {
        if (inherited_cpc == "null") {
            let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.your_bid.bid_active;
            tooltip = tooltip.replace("{bid}", your_bid);
            return tooltip;
            // return "Your bid is " + your_bid;
        } else {
            let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.your_bid.inherited_bid;
            tooltip = tooltip.replace("{bid}", formatThousand(Number(inherited_cpc).toFixed(2)));
            return tooltip;
            // return "Your bid is $" + formatThousand(Number(inherited_cpc).toFixed(2)) + ". This bid is inherited from parent topic cluster.";
        }
    }

    let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.your_bid.bid_active;
    tooltip = tooltip.replace("{bid}", your_bid);
    return tooltip;
    // return "Your bid is " + your_bid;
}
 