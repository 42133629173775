import { loadersMap } from "../maps";
import defaultLoader from "./default";

export function loadersModule(publisher_id) {
  console.log('publisher_id', publisher_id)
  try {
    const isPublisherIdPresent = Object.keys(loadersMap).includes(
      String(publisher_id)
    );
    if (isPublisherIdPresent) {
      return loadersMap[publisher_id]
    } else {
      return loadersMap[0]
    }
  } catch (error) {
    console.error('Error loaders module:', error);
    return defaultLoader();
  }
}
