import { Badge } from "react-bootstrap";

export function InventoryTypeRenderer({ value }) {
    console.log('value', value);
    if (!value) {
        return null;
    }

    return <Badge
        bg="campaign-name"
        className="me-1 bg-campaign-name"
        style={{
            width: 'max-content',
            maxWidth: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pointerEvents: 'none'
        }}
    >
        {value}
    </Badge>;
}
