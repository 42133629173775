import React, { useEffect, useState } from "react";
import '../loader.css';
import CampaignClicks from "../../adv/campaign/campaign/CampaignClicks";

export default function CustomerClicksIframe() {
  // get inventory id and campaing id from url
  const [iframe, setIframe] = useState(null);

  useEffect(() => {
    setIframe(1);
  }, []);

  if (iframe === 1) {
    return (
      <CampaignClicks isIframe={true} />
    )
  } else {
    return (
      <div>
        <span className="loader"></span>
      </div>
    )
  }
}