import { Col } from "react-bootstrap";

export default function CreditsLackAlert({
  isCreditBalanceSmallerThanTopBid,
  closedAlert,
  setClosedAlert,
}) {
  return (
    isCreditBalanceSmallerThanTopBid ? (
        !closedAlert ? (
          <Col md="12">
            <div className="fade alert alert-dismissible danger-system-alert show mb-0">
              <button
                type="button"
                className="btn-close"
                aria-label="Close alert"
                onClick={() => setClosedAlert(true)}
              ></button>
              <div className="banner">
                <h3 className="banner-title">
                  You don't have enough credits to continue bidding at the
                  moment (your current credit total is $0).
                </h3>
                <p className="banner-text">
                  Please top up on the settings page to bid on PPC
                  inventory.
                </p>
              </div>
            </div>
          </Col>
        ) : (
          <></>
        )
      ) : (
        <></>
      )
  );
}