import React, { useEffect, useState } from "react";
import { getPeriodCampaignImpressionsAndClicks } from "../../../services/micro";
import { Card, Placeholder, Col, Nav } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useCookiesContext, CookiesKeyNames } from "../../../contexts/CookiesContext";
import TrackableButton from "../../../mixpanel/components/TrackableButton";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import DatePicker from "../../../pub/components/DatePicker";

const formatDate = (date) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-UK", options).format(date);
};

function getCSSVariableValue(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
}

const Ranges = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "yesterday",
    value: [
      startOfDay(addDays(new Date(), -1)),
      endOfDay(addDays(new Date(), -1)),
    ],
  },
  {
    label: "Last 30 Days",
    value: [startOfDay(addDays(new Date(), -29)), endOfDay(new Date())],
  },
];

export default function CampaignImpressionsAndClicks(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const { currentPeriod, campaignId, handleOpenDateRangePicker, isDateRangePickerOpen, setIsDateRangePickerOpen, handleDateRange } = props;
  const [response, setResponse] = useState([]);
  const [colors, setColors] = useState({
    primary: "",
    chartSecondary: "",
    columnChart: "",
  });

  const { cookiesData, getPathKey } = useCookiesContext();
  const publisherId = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];

  useEffect(() => {
    const primary = getCSSVariableValue('--chart-color-3');
    const chartSecondary = getCSSVariableValue('--chart-color-2');
    const columnChart = getCSSVariableValue('--chart-color-1');
    setColors({ primary, chartSecondary, columnChart });
  }, []);

  useEffect(() => {
    if (currentPeriod) {
      getPeriodCampaignImpressionsAndClicks(
        currentPeriod,
        campaignId,
        token
      ).then((response) => {
        setResponse(response);
      });
    }
  }, [props]);

  const optionFour = {
    chart: {
      parentHeightOffset: 0,
      stacked: false,
      toolbar: { show: false },
      animations: {
        enabled: true,
      },
      type: "line", 
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        bottom: -20,
        left: 20,
        right: 0,
      },
    },
    colors: [colors.columnChart, colors.chartSecondary, colors.primary], 
    plotOptions: {
      bar: { columnWidth: "50%" },
    },
    stroke: {
      curve: ["smooth", "smooth", "smooth"], 
      width: [1.5, 1.5, 2], 
    },
    fill: {
      type: ["solid", "gradient", "solid"],
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.35,
        opacityTo: 0.2,
        stops: [0, 100],
        gradientToColors: ["#f3f5fc"], 
      },
    },
    xaxis: {
      tickAmount: 15,
      labels: {
        style: {
          colors: "#ccc",
          fontSize: "11px",
        },
        rotate: -45,
        rotateAlways: true, 
      },
    },
    yaxis: [
      {
        seriesName: "Clicks",
        opposite: true,
        labels: {
          show: false, 
        },
      },
      {
        seriesName: "Impressions",
        opposite: false,
        labels: {
          show: false, 
        },
      },
      {
        seriesName: "Campaign Spend, $",
        opposite: false,
        labels: {
          show: false, 
        },
      },
    ],
    dataLabels: { enabled: false },
    tooltip: {
      shared: true,
      intersect: false
    },
    legend: { show: true },
  };

  if (response && response.stats) {

    // HTR usecase
    // if (publisherId == 67) {
    //   response.stats = [response.stats[0]];
    // }

    const categories = response.dates;
    optionFour.xaxis.categories = categories;
    optionFour.xaxis.tickAmount = 15;

    return (
      <React.Fragment>
        <Card.Header>
          <div>
            <Card.Title as="h6" className="d-flex align-items-center">
              <div className="me-2">Impressions &amp; Clicks</div>
              <div className="fs-xs text-secondary">
                {"("}
                {new Date(currentPeriod[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                {new Date(currentPeriod[0]).getDate()}{" "}
                {new Date(currentPeriod[0]).getFullYear()} -{" "}
                {new Date(currentPeriod[1]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                {new Date(currentPeriod[1]).getDate()}{" "}
                {new Date(currentPeriod[1]).getFullYear()}
                {")"}
              </div>
            </Card.Title>
            <div className="d-flex align-items-center fs-xs text-secondary mb-0 mt-1">
              <i className="ri-information-line me-1"></i>
              Data aggregated below is delayed by 10 minutes.
            </div>
          </div>
          <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>

          
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <TrackableButton
              variant="btn-outline-primary"
              className={`d-flex align-items-center gap-2 btn btn-outline-primary`}
              onClick={handleOpenDateRangePicker}
              campaignId={campaignId}
              buttonName={"Date Range in adv analytics"}
            >
              <span className="fw-semibold">Date Range</span>
              <i className="ri-arrow-right-s-line"></i>
              {currentPeriod && currentPeriod.length === 2
                ? ` ${formatDate(currentPeriod[0])} - ${formatDate(
                    currentPeriod[1]
                  )}`
                : ""}
            </TrackableButton>
            {isDateRangePickerOpen && (
              <DatePicker
                isDateRangePickerOpen={isDateRangePickerOpen}
                setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                ranges={Ranges}
                onChange={handleDateRange}
                currentPeriod={currentPeriod}
              />
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <ReactApexChart
            series={response.stats}
            options={optionFour}
            type="bar"
            height={300}
            className="apex-chart-nine"
          />
        </Card.Body>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
