import React, { useEffect, useState } from "react";
import { getPeriodInventoryTotals } from "../../../services/micro";
import { Card, Placeholder, Col, Row } from "react-bootstrap";

export default function InventoryTotals(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState([]);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (
        currentPeriod[0] &&
        currentPeriod[1] &&
        previousPeriod[0] &&
        previousPeriod[1]
      ) {
        getPeriodInventoryTotals(currentPeriod, previousPeriod).then(
          (response) => {
            if (response) {
              setResponse(response);
            }
          }
        );
      }
    }
  }, [currentPeriod, previousPeriod]);

  if (response && response.length) {
    return (
      <React.Fragment>
        {response &&
          response.map((card, index) => (
            <Col xs="6" xl="3" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <h2 className="card-value mb-1">{card.value}</h2>
                  <Card.Title
                    as="label"
                    className="d-block mb-1 fw-medium text-dark"
                  >
                    {card.label}
                  </Card.Title>
                  <small className="subtext">
                    <span
                      className={
                        "d-inline-flex text-" +
                        (card.status === "up" ? "success" : "danger")
                      }
                    >
                      {card.change}{" "}
                      <i
                        className={
                          "ri-arrow-" +
                          (card.status === "up" ? "up" : "down") +
                          "-line"
                        }
                      ></i>
                    </span>{" "}
                    than previous period
                  </small>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row className="g-3 p-0" style={{ color: "var(--bs-body-color)" }}>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
