function getBaseDomain(domain) {
    const domainParts = domain.split(".");
    if (domainParts.length >= 2) {
      return domainParts.slice(-2).join("."); // Отримуємо останні 2 частини (revbox.co, revbox.fr)
    }
    return domain;
  }
  
  function getRootDomain(domain) {
    const parts = domain.split(".");
    if (parts.length >= 2) {
      return parts[parts.length - 2]; // Отримуємо передостанню частину (revbox)
    }
    return domain;
  }

export function validateEmailAndUrl(email, url) {
    if (!email || !url) {
        return {
            valid: false,
        error: "Please enter your email and URL.",
      };
    }
  
    const emailParts = email.trim().split("@");
    if (emailParts.length !== 2) {
      return {
        valid: false,
        error: "Incorrect email format: must contain exactly one '@' character.",
      };
    }
  
    let emailDomain = emailParts[1].trim().toLowerCase();
    emailDomain = emailDomain.replace(/^\.+|\.+$/g, "");
  
    let parsedUrl;
    try {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }
      parsedUrl = new URL(url.trim());
    } catch (error) {
      console.log(error);
      return {
        valid: false,
        error: "Incorrect URL format",
      };
    }
  
    let urlHostname = parsedUrl.hostname.toLowerCase();
    if (urlHostname.startsWith("www.")) {
      urlHostname = urlHostname.substring(4);
    }
    urlHostname = urlHostname.replace(/^\.+|\.+$/g, "");
  
    // Використання getBaseDomain для перевірки базових доменів
    const emailBaseDomain = getBaseDomain(emailDomain);
    const urlBaseDomain = getBaseDomain(urlHostname);
  
    // Використання getRootDomain для перевірки основної частини
    const emailRootDomain = getRootDomain(emailDomain);
    const urlRootDomain = getRootDomain(urlHostname);
  
    console.log(`Email base domain: ${emailBaseDomain}, URL base domain: ${urlBaseDomain}`);
    console.log(`Email root domain: ${emailRootDomain}, URL root domain: ${urlRootDomain}`);
  
    // Перевірка збігу базових доменів або основного імені компанії
    if (emailBaseDomain === urlBaseDomain || emailRootDomain === urlRootDomain) {
      return { valid: true };
    } else {
      return {
        valid: false,
        error: `Email domain ("${emailDomain}") does not match the domain of the URL ("${urlHostname}").`,
      };
    }
  }
  