import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import img1 from "../assets/img/img1.jpg";
import { saveNewPassword } from "../services/micro";
import { useNavigate } from "react-router-dom";
import { logosModule } from "../modules/logos";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

// http://localhost:3000/change-password?recovery_token=[<>]&token=[<>]&pub=[<>]
export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const { cookiesData, updateCookiesProperty, getPathKey } = useCookiesContext()

    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode]
    const urlParams = new URLSearchParams(window.location.search)
    const pubParam = urlParams.get('pub')
    const setPasswordParam = urlParams.get('set_password')


  const publisherIdByAbbr = Number(pubParam);

  const [siteLogo, setSiteLogo] = useState(null);

  useEffect(() => {
    setSiteLogo(logosModule(publisherIdByAbbr, currentSkin));
  }, [publisherIdByAbbr, currentSkin]);

  useEffect(() => {
    return () => {
      updateCookiesProperty(CookiesKeyNames.publisherId, publisherIdByAbbr);
    }
  }, [publisherIdByAbbr]);

    const navigate = useNavigate()

    const recoveryToken = new URLSearchParams(window.location.search).get('recovery_token')
    const token = new URLSearchParams(window.location.search).get('token')

    const handleChangePassword = () => {
        if (newPassword !== confirmPassword) {
            toast.error('Password and Confirm Password are not matched.')
            return
        }

        if (!newPassword || !confirmPassword) {
            toast.error('Please enter your new password.')
            return
        }

        saveNewPassword(token, recoveryToken, newPassword)
            .then(res => {
                if (res.success) {
                    toast.success('Your password has been changed successfully.')
                    navigate('/pages/signin')
                } else {
                    toast.error(res.error)
                    return
                }
            })
            .catch(err => {
                toast.error('Failed to change your password.')
                console.log(err)
            })
    }

    return (
        <div className="page-sign">
            <Card className="card-sign">
                <Card.Header className="d-flex flex-column align-items-start">
                    <Link to="/" className="header-logo">
                        {siteLogo}
                    </Link>
                    <Card.Title className="text-center">{setPasswordParam ? 'Set Password' : 'Change Password'}</Card.Title>
                    <Card.Text className="text-center">Please enter your new password.</Card.Text>
                </Card.Header>
                <Card.Body>
                    <div className="mb-4">
                        <Form.Label className="d-flex justify-content-between">Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter your new password" onChange={e => setNewPassword(e.target.value)} />
                    </div>
                    <div className="mb-4">
                        <Form.Label className="d-flex justify-content-between">Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter your new password again"
                            onChange={e => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <Button variant="primary" className="btn-sign" onClick={handleChangePassword}>
                        {setPasswordParam ? 'Set Password' : 'Change Password'}
                    </Button>
                </Card.Body>
                <Card.Footer>
                    Not you? <Link to="/pages/signin">Sign in with different account</Link>
                </Card.Footer>
            </Card>
        </div>
    )
}
