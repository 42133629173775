import { AuctionCampaignStatusCardMap } from '../maps';
import defaultAuctionCampaignStatusCard from './default';

export function AuctionCampaignStatusCardModule(publisher_id, props) {
    try {
        const isPublisherIdPresent = Object.keys(AuctionCampaignStatusCardMap).includes(String(publisher_id))

        if (isPublisherIdPresent) {
          return AuctionCampaignStatusCardMap[publisher_id](props);
        } else {
          return defaultAuctionCampaignStatusCard(props);
        }
    } catch (error) {
        console.error('Error auction campaign status card module:', error);
        return defaultAuctionCampaignStatusCard(props);
    }
}
