const columnsWidths = {
    'ID': 100,
    'name': 250,
    'status': 100,
    'views': 150,
    'yourClicks': 120,
    'position': 100,
    'yourBid': 70,
    'topBid': 70,
    'missedClicksForecast': 190,
    'action': 130,
}

export function createColumnDefinitionsPPC(columns, customColumns) {
    const toCamelCase = (str) => {
        if (str.toUpperCase() === 'ID') {
            return str.toUpperCase();
        } else
            return str
                .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                    index === 0 ? match.toLowerCase() : match.toUpperCase()
                )
                .replace(/\s+/g, '')
                .replace(/^ID$/, 'id');
    };

    const current = columns.map(column => {
        const customColumn = customColumns?.find((custom) => custom.field === column)
        return ({
            headerComponentParams: {
                template: `<div style="display: inline-flex; ${column === 'Name' ? '' : 'align-items: center; justify-content: center;'} width: 100%;">
                    ${column}
                    ${(column === 'Missed Clicks Forecast' || column === 'Your Clicks') ? 
                    '<i class="ri-information-line text-secondary align-icon"></i>' : ''}
                </div>`
            },
            // headerComponentParams: {
            //     template: (column === 'Missed Clicks Forecast' || column === 'Your Clicks') ? 
            //     `<div style="display: inline-flex; align-items: center;">${column} <i class="ri-information-line text-secondary align-icon"></i></div>` : column,
            // },
            headerTooltip: customColumn?.headerTooltip || column,
            tooltipValueGetter: customColumn?.tooltipValueGetter || null,
            field: toCamelCase(column),
            colId: toCamelCase(column),
            hide: column === 'ID',
            minWidth: columnsWidths[toCamelCase(column)],
            width: columnsWidths[toCamelCase(column)],
            pinned: column === 'Action' ? 'right' : column === 'Name' ? 'left' : null,
            suppressHeaderFilterButton: true,
            sortable: false,
            menuTabs: [],
            suppressMenu: true,
            cellRenderer: customColumn?.cellRenderer ? (params) =>
            {
                return customColumn.cellRenderer(params)
            } : null,
            headerClass: 'ag-header-cell-center',
            cellClass: 'center-column',
        })
    });

    return current;
}