import { convertToCamelCaseArray } from "./convert-to-camel-case";
import { createColumnDefinitionsPPC } from "./create-column-definitions-ppc";

export function getServerSideDatasourcePPC(getData, setColumns, search, customColumns, campaignId) {    
    return {
        getRows: async (params) => {
            const currentPage = params.api.paginationGetCurrentPage() + 1;
            const response = await getData({search, page: currentPage}, campaignId); 

            setTimeout(() => {
                if (response.success) {
                                           
                    const transformedData = convertToCamelCaseArray(response.data);

                    const classifiedColumns = response.data.columns;
                    const columnDefs = createColumnDefinitionsPPC(classifiedColumns, customColumns);        

                    if (columnDefs) {
                        setColumns(columnDefs);
                    }

                    params.success({
                        rowData: transformedData,
                        rowCount: response.total,
                    });
                } else {
                    params.fail();
                }
            }, 200);
        },
    };
};
