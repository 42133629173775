import React, { useCallback, useEffect, useState } from "react";
import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function ClusterNameRenderer({ node, value }) {
    const [expanded, setExpanded] = useState(node.expanded);

    useEffect(() => {
        const expandListener = (event) => setExpanded(event.node.expanded);

        node.addEventListener("expandedChanged", expandListener);

        return () => {
        node.removeEventListener("expandedChanged", expandListener);
        };
    }, []);

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

    // inventory.name + '@' + views + '@' + inventory.inventory_hirarchy_level + '@' + getPath(inventories, item.inventory_id).length + '@' + min_bid + '@' + status,
          
    const name = value.split("@")[0];
    const visits = value.split("@")[1];
    const inventory_hirarchy_level = value.split("@")[2];
    const pathLength = value.split("@")[3];
    const min_bid = value.split("@")[4];
    const status = value.split("@")[5];
    const allChildrenCount = node.allChildrenCount;
    const level = node.level + 1;


    return (
        <div 
            className="d-flex"
            style={{
                paddingLeft: `${node.level * 25 + 20}px`,
            }}
            onClick={onClick}
        >
            <div className="d-flex align-items-center" style={{ width: '20px', marginLeft: '-20px' }}>
                { node.group && (
                    <div
                        style={{
                            cursor: "pointer",
                            display: "inline-block",
                        }}
                    >
                        <span style={{ fontSize: "18px" }}>
                            <i class={expanded ? "ri-arrow-down-s-line text-secondary" : "ri-arrow-right-s-line text-secondary"}></i>
                        </span>
                    </div>
                )}
            </div>
            <div
                className='d-flex flex-column ms-1'
                // title={`Current invetory recived ${visits} visits per last 30 days`}
                >
                <small className='text-primary me-1'>C{level}</small>
            </div>
            <div
                className='d-flex flex-column ms-1'
            >
                <div className="table-item-name">
                    {name} 
                </div>
                <div 
                    className="align-items-center text-secondary"
                    style={{ fontSize: '12px', marginTop: '-20px'}}
                >
                    {/* <span className='fw-bold'>Level {level} Topic {level === 1 ? 'Cluster' : 'Sub-Cluster'}</span>
                    { allChildrenCount > 0 && <span className=''>|</span> }
                    { allChildrenCount > 0 && <span className=''>{allChildrenCount} Topic Sub-Cluster{allChildrenCount > 1 ? 's' : ''}</span> } */}
                    {visits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Views
                    <span className=''> | </span>
                    <span className=''>${min_bid}+</span>
                    {/* <span style={{marginLeft: '10px'}} className="text-warning"> 
                        <span className="badge-dot me-2 bg-warning"></span>
                        {status.toLowerCase()}
                    </span> */}
                </div>
            </div>
        </div>
    );
}

export function ClusterNameColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_cluster_column_headers.topic;
}

export function ClusterNameCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    return taxonomy.tooltips.ppc_cluster_column_inlines.topic.replace("{views}", visits);
}
 