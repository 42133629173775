import Default_Taxonomy from './default';
import HTR_Taxonomy from './67';

export function TaxonomyModule(publisher_id) {
    try {
        if (publisher_id === 67) {
            return HTR_Taxonomy();
        } else {
            return Default_Taxonomy();
        }
    } catch (error) {
        return Default_Taxonomy();
    }
}
