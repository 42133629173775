import axios from "axios";
import { getToken } from "../helpers/get-token";

export default function verifyToken() {
  const token = getToken();

  if (!token) {
    console.error("Case #44: Authorization token is missing.");
    return;
  }

  axios
    .get(process.env.REACT_APP_HUB_URL + "/api/auth/verify-token/" + token)
    .then((res) => {
      if (!res.data.success) {
        console.error("Case #45: Error fetching user data");
      }
    })
    .catch((err) => {
      console.error("Case #46: Error fetching user data:", err);
    });
}
