import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
      style={{ cursor: "pointer" }}
    >
      {children}
    </div>
  ));

export default CustomToggle;