import { placeholderLogoMap } from '../maps';
import defaultLogo from './default';

export function placeholderLogoModule(publisher_id, theme) {
    try {
        const isPublisherIdPresent = Object.keys(placeholderLogoMap).includes(String(publisher_id))

        if (isPublisherIdPresent) {
          return placeholderLogoMap[publisher_id](theme ? theme : 'light');
        } else {
          return defaultLogo(theme);
        }
    } catch (error) {
        console.error('Error placeholder logo module:', error);
        return defaultLogo(theme);
    }
}
