import { useState, useEffect } from "react";
import { getRecentNotifications } from "../services/micro";
import { toast } from "react-toastify";

export function useRecentNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [newNotificationAmount, setNewNotificationAmount] = useState(0);

  useEffect(() => {
    getRecentNotifications()
      .then((res) => {
        setNewNotificationAmount(res.data.new);
        setNotifications(res.data.notifications);
      })
      .catch((e) => {
        toast.error("Server error");
      });
  }, []);

  return { notifications, newNotificationAmount };
}
