import React, { useEffect, useState } from "react";
import AdvertiserAnalytics from "../../adv/campaign/AdvertiserAnalytics";
import { mixpanelInstance } from "../../mixpanel/instance";
import '../loader.css';

export default function CustomerIframe() {
  // get inventory id and campaing id from url
  const [iframe, setIframe] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const campaignId = urlParams.get("campaignId");

    mixpanelInstance.track("Customer Analytics Page Loaded");

    setIframe(1);
  }, []);

  if (iframe === 1) {
    return (
      <AdvertiserAnalytics isIframe={true} />
    )
  } else {
    return (
      <div>
        <span className="loader"></span>
      </div>
    )
  }
}