import { AuctionCreditBalanceCardMap } from '../maps';
import DefaultAuctionCreditBalanceCard from './default';

export function AuctionCreditBalanceCardModule(publisher_id, props) {
    try {
        const isPublisherIdPresent = Object.keys(AuctionCreditBalanceCardMap).includes(String(publisher_id))

        if (isPublisherIdPresent) {
          return AuctionCreditBalanceCardMap[publisher_id](props);
        } else {
          return DefaultAuctionCreditBalanceCard(props);
        }
    } catch (error) {
        console.error('Error auction credit balance card module:', error);
        return DefaultAuctionCreditBalanceCard(props);
    }
}
