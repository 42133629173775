import { useState, useEffect } from 'react';
import { getAllRoles } from '../services/micro';

export function useRoles(userRole) {
  const [subRoles, setSubRoles] = useState([]);
  const [userAccesses, setUserAccesses] = useState([]);

  useEffect(() => {
    getAllRoles()
      .then((res) => {
        if (res?.success) {
          setSubRoles(res.data);
          const userAccessesData = res.data.find(
            (role) => role.name === userRole
          )?.avaliable_accesses;
          setUserAccesses(userAccessesData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userRole]);

  return { subRoles, userAccesses };
}
