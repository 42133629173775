import React from "react";
import { getPeriodCampaignInventoryStats } from "../../../services/micro";
import { Card, Col, Nav } from "react-bootstrap";

import CampaignInventoryStatsTable from "../../../components/CampaignInventoryStatsTable";

const colWidth = {
  account_name: 250,
  account_legal_name: 290,
  account_registered_address_country_id: 100,
  account_vat_number: 180,
  created_at: 200,
};

const hiddenColumns = [
  "id",
];


const chartOption = {
  indexAxis: "y",
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      max: 100,
      grid: {
        borderColor: "#000",
        color: "#f3f5f9",
      },
      ticks: {
        color: "#212830",
        font: {
          size: 10,
          weight: "500",
        },
      },
    },
    y: {
      grid: {
        borderWidth: 0,
        color: "#f3f5f9",
      },
      ticks: {
        color: "#212830",
        font: {
          size: 12,
        },
      },
    },
  },
};

export default function CampaignInventoryStats(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const { currentPeriod, previousPeriod, skin, campaignId, isIframe, view } = props;

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }

  return (
    <React.Fragment>
      <Card.Header>
        <div>
          <Card.Title as="h6" className="d-flex align-items-center">
            <div className="me-2">Inventory Stats</div>
            <div className="fs-xs text-secondary mb-0 mt-1">
              {"("}
              {new Date(currentPeriod[0]).toLocaleString("default", {
                month: "short",
              })}{" "}
              {new Date(currentPeriod[0]).getDate()}{" "}
              {new Date(currentPeriod[0]).getFullYear()} -{" "}
              {new Date(currentPeriod[1]).toLocaleString("default", {
                month: "short",
              })}{" "}
              {new Date(currentPeriod[1]).getDate()}{" "}
              {new Date(currentPeriod[1]).getFullYear()}
              {")"}
            </div>
          </Card.Title>
          <div className="d-flex align-items-center fs-xs text-secondary mb-0 mt-1">
            <i className="ri-information-line me-1"></i>
            Data aggregated below is delayed by 10 minutes.
          </div>
        </div>
        <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
      </Card.Header>
      <Card.Body className="p-3 p-xl-3">
        <div
          className="table-responsive cursor-pointer"
        >
          <CampaignInventoryStatsTable
            getData={getPeriodCampaignInventoryStats}
            hiddenColumns={hiddenColumns}
            colWidth={colWidth}
            skin={skin}
            currentPeriod={currentPeriod}
            previousPeriod={previousPeriod}
            campaignId={campaignId}
            token={token}
            view={view}
          />
        </div>
      </Card.Body>
    </React.Fragment>
  );
}
