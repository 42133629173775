import React from "react";
import { Button } from "react-bootstrap";
import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function ClusterActionRenderer({data, value}) {
  if (!value) {
    return null;
  }
  
  const status = value.split("@")[0].toUpperCase();
  const parentBidIsDisactivated = value.split("@")[1] == "true";
  const allowed = value.split("@")[3] === "false" ? true : false;
  
  const urlParams = new URLSearchParams(window.location.search);
  const campaignId = urlParams.get("campaignId");
  const advId = urlParams.get("advId");

  const openStats = () => {
    window.open(`/adv/campaign/ppc/clustering?inventoryId=${data.ID}&campaignId=${campaignId}&advId=${advId}`, "_blank");
  }

  if (status === "INACTIVE" || status === "DRAFT") {
    return (
        <div>
            <Button variant="outline-primary" size="sm" className="" onClick={openStats}>
              <i className="ri-bar-chart-line"></i>
            </Button>
            {/* <button className="btn bg-success btn-sm text-white" style={{ width: '100px'}} onClick={openStats}>
              View Stats
            </button> */}
            {/* <Button variant="outline-success" size="sm" className="fs-11" style={{ width: '100px'}} onClick={openStats}>
                View Stats
            </Button> */}
            <button variant={allowed ? "outline-primary" : "primary"} size="sm" className="" style={{marginLeft: '5px', width: '100px'}} disabled={allowed}>
              Start Bidding
            </button>
        </div>
    );
  } else {
    return (
      <div>
        {/* <button className="btn bg-success btn-sm text-white" style={{ width: '100px'}} onClick={openStats}>
          View Stats
        </button> */}
        <Button variant="outline-primary" size="sm" className="" onClick={openStats}>
          <i className="ri-bar-chart-line"></i>
        </Button>
        <Button variant={allowed ? "outline-primary" : "primary"} size="sm" className="" style={{marginLeft: '5px', width: '100px'}} disabled={allowed}>
          {allowed ? <><i className="ri-lock-2-line"></i> Locked</> : "Update Bid"}
        </Button>
      </div>
    );
  }
}

export function ClusterActionCellColumnHeaderTooltipRenderer() {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  return taxonomy.tooltips.ppc_cluster_column_headers.action;
}

export function ClusterActionCellTooltipRenderer({ value }) {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  if (!value) {
    return null;
  }
  
  const status = value.split("@")[0].toUpperCase();
  const parentBidIsDisactivated = value.split("@")[1] == "true";

  if (parentBidIsDisactivated) {
    return taxonomy.tooltips.ppc_cluster_column_inlines.action.parent_bid_is_disactivated;
  }

  if (status === "INACTIVE" || status === "DRAFT") {
    return taxonomy.tooltips.ppc_cluster_column_inlines.action.start_bidding;
  } else {
    return taxonomy.tooltips.ppc_cluster_column_inlines.action.update_bid;
  }
}
  
