import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getMe, login } from "../services/micro";
import { setUser } from "../features/user";
import { logosModule } from "../modules/logos";
import { CookiesKeyNames, useCookiesContext, isCookiesEnabled, getPathKey } from "../contexts/CookiesContext";
import { loadersModule } from "../modules/loaders";
import Cookies from "universal-cookie";
import { GetPubAbbr } from "../helpers/get-pub-abbr";

const cookies = new Cookies();

function getPublisherId() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (urlDomain === "app.netnetix.com") {
    return 34;
  }

  if (urlDomain === "stage.netnetix.com") {
    return 34;
  }

  return urlParams.get("pub") || 1;
}

export const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [siteLogo, setSiteLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [publisherId, setPublisherId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCookiesProperty, removeCookiesProperty, cookiesData } =
    useCookiesContext();

  useEffect(() => {
    const publisher_id = getPublisherId();

    if (!publisher_id) {
      const publisherIdFromCookies =
        cookiesData["pub"]?.[CookiesKeyNames.publisherId];
      if (publisherIdFromCookies) {
        navigate("/pages/signin?pub=" + publisherIdFromCookies);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please enter your email and password.");
      return;
    }

    login(email, password)
      .then((res) => {
        if (res.success) {
          toast.success("Login successful. Redirecting...");
          dispatch(setUser(res.data));

          if (res.data.default_theme === "dark") {
            updateCookiesProperty(CookiesKeyNames.skinMode, "dark");
          }
          updateCookiesProperty(CookiesKeyNames.token, res.data.token);
          updateCookiesProperty(
            CookiesKeyNames.publisherId,
            res.data.publisher_id
          );
          removeCookiesProperty(CookiesKeyNames.currentPeriod);
          removeCookiesProperty(CookiesKeyNames.previousPeriod);

          // if (res.data.publisher_id !== 1) {
          //   let skinMode = isCookiesEnabled
          //     ? cookies.get(getPathKey())[CookiesKeyNames.skinMode]
          //     : JSON.parse(localStorage.getItem(getPathKey()) || "{}")[
          //         CookiesKeyNames.skinMode
          //       ];
          //   let HTMLTag = document.querySelector("html");

          //   import(`../modules/styles/${res.data.publisher_id}.scss`)
          //     .then(() => console.log("Stylesheet loaded"))
          //     .catch(() => {
          //       import(`../modules/styles/default.scss`)
          //         .then(() => console.log("Default stylesheet loaded"))
          //         .catch(() => console.log("No stylesheet loaded"));
          //     });

          //   if (skinMode) {
          //     HTMLTag.setAttribute("data-skin", skinMode);
          //   }
          // } else {
          //   import(`../modules/styles/default.scss`)
          //     .then(() => console.log("Default stylesheet loaded"))
          //     .catch(() => console.log("No stylesheet loaded"));
          // }

          setLoading(true);
          setPublisherId(res.data.publisher_id);

          setTimeout(() => {
            if (
              res.data.role !== "developer" &&
              res.data.role !== "content_manager" &&
              res.data.role !== "sales"
            ) {
              // const navigateLink = isIOSOrSafari() ? "/pub/dashboard/revenue?refresh=true&token=" + res.data.token : "/pub/dashboard/revenue?refresh=true"
              // const navigateLink = "/pub/dashboard/revenue?refresh=true"
              const navigateLink =
                "/pub/dashboard/revenue?pub=" + res.data.publisher_id;

              window.location.href = navigateLink;
              // navigate(navigateLink);
            } else {
              // const navigateLink = isIOSOrSafari() ? "/pub/inventory/queue?refresh=true&token=" + res.data.token : "/pub/inventory/queue?refresh=true"
              const navigateLink =
                "/pub/inventory/queue?pub=" + res.data.publisher_id;

              window.location.href = navigateLink;
              // navigate(navigateLink);
            }
          }, 100);
        } else {
          toast.error("Login failed.");
        }
      })
      .catch((err) => {
        toast.error("Login failed.");
        console.log("Error", err);
      });
  };

  useEffect(() => {
    const publisherIdByAbbr = GetPubAbbr();
    console.log("publisherIdByAbbr", publisherIdByAbbr);
    let siteLogo = logosModule(publisherIdByAbbr);
    
    if (!publisherIdByAbbr) {
      const publisherIdFromCookies =
        cookiesData["pub"]?.[CookiesKeyNames.publisherId];

      console.log("publisherIdFromCookies", publisherIdFromCookies);
      siteLogo = logosModule(publisherIdFromCookies);
    }

    setSiteLogo(siteLogo);
    setPublisherId(publisherIdByAbbr);
  }, [cookiesData, publisherId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");

    if (!token) return;

    const login = async () => {
      const res = await getMe(token);
      if (res.success) {
        toast.success("Login successful. Redirecting...");
        dispatch(setUser(res.data));

        if (res.data.default_theme === "dark") {
          updateCookiesProperty(CookiesKeyNames.skinMode, "dark");
        }
        updateCookiesProperty(CookiesKeyNames.token, res.data.token);
        updateCookiesProperty(
          CookiesKeyNames.publisherId,
          res.data.publisher_id
        );
        removeCookiesProperty(CookiesKeyNames.currentPeriod);
        removeCookiesProperty(CookiesKeyNames.previousPeriod);

        if (
          res.data.role !== "developer" &&
          res.data.role !== "content_manager" &&
          res.data.role !== "sales"
        ) {
          // const navigateLink = isIOSOrSafari() ? "/pub/dashboard/revenue?refresh=true&token=" + res.data.token : "/pub/dashboard/revenue?refresh=true"
          // const navigateLink = "/pub/dashboard/revenue?refresh=true"
          const navigateLink =
            "/pub/dashboard/revenue?pub=" + res.data.publisher_id;

          window.location.href = navigateLink;
          // navigate(navigateLink);
        } else {
          // const navigateLink = isIOSOrSafari() ? "/pub/inventory/queue?refresh=true&token=" + res.data.token : "/pub/inventory/queue?refresh=true"
          const navigateLink =
            "/pub/inventory/queue?pub=" + res.data.publisher_id;

          window.location.href = navigateLink;
          //navigate(navigateLink);
        }
      } else {
        toast.error("Login failed.");
      }
    };

    login();
  }, []);

  if (loading && publisherId) {
    const logoGif = loadersModule(Number(publisherId));

    return (
      <></>
    );
  }

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header className="pb-3">
          <Link to="/" className="header-logo">
            {siteLogo}
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body className="pb-3 pt-3">
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="Enter your email address"
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to={`/forgot-password?pub=${publisherId}`}>Forgot password?</Link>
              </Form.Label>
              <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Enter your password"
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              Sign In
            </Button>
            {/* <div className="divider">
              <span>Subject to Terms & Conditions</span>
            </div> */}
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

// eslint-disable-next-line no-lone-blocks
// {
//    /* <Card.Footer className="pt-4">
//   Don't have an account? <Link to="/pages/signup">Create One</Link>.
// </Card.Footer> */
// }
