import { Col } from "react-bootstrap";

export default function NotShownInheritedAuctionsAlert({
    clusterInheritedAuctionsAndNotShown,
    closedAlert,
    setClosedAlert,
}) {
    return (
        clusterInheritedAuctionsAndNotShown.length > 0 ? (
            !closedAlert ? (
              <Col md="12">
                <div className="fade alert alert-dismissible warning-system-alert show mb-0">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close alert"
                    onClick={() => setClosedAlert(true)}
                  ></button>
                  <div className="banner">
                    <h3 className="banner-title">
                      Your ad is not shown on{" "}
                      {clusterInheritedAuctionsAndNotShown.length} topic
                      clusters.
                    </h3>
                    <p className="banner-text">
                      Please increase your bid to get your ad shown on these
                      topic clusters.
                    </p>
                  </div>
                </div>
              </Col>
            ) : (
              <></>
            )
          ) : (
            <></>
          )
    )
}