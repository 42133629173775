import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function PublisherApplicationEdit() {
  const urlParams = new URLSearchParams(window.location.search);
  const values = Object.fromEntries(urlParams.entries());
  console.log("values", values);

  return (
    <RecordEdit
      values={values}
      title="Edit Application"
      table_name="client_applications"
      breadcrumb="Application"
      redirect="/pub/customers/applications"
      item_name="Application"
    />
  );
}
