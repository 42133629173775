import React from "react";
import RecordCreate from "../../components/RecordCreate";
const PublisherApplicationsCreate = () => {
  return (
    <RecordCreate
      title="Create Application"
      table_name="client_applications"
      breadcrumb="Applications"
      redirect="/pub/customers/applications"
    />
  );
};

export default PublisherApplicationsCreate;
