import axiosInstance from '../../axios/instance'
import { getToken } from '../../helpers/get-token'

export async function editClientApplication(publisher_id, values) {
    try {
        const token = getToken()
        const response = await axiosInstance.put(`/api/client-applications/${publisher_id}`, values, {
            headers: {
                Authorization: token
            }
        })

        return response.data
    } catch (error) {
        console.log(error)
        throw error // Re-throw the error for further handling if needed
    }
}
