import { onReadNotification } from "../helpers/on-read-notifications";

function NotificationList( notifications, notificationPath ) {
    const notiList = notifications.map((item, key) => {
      return (
        <li
          className="list-group-item"
          key={key}
          style={item.status === "read" ? { opacity: 0.35 } : {}}
          onClick={() => onReadNotification(item.id, item.link, notificationPath)}
        >
          {item.icon_url ? (
            <div
              className={item.status === "unread" ? "avatar online" : "avatar"}
              style={{
                backgroundImage: "url(" + item.icon_url + ")",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            />
          ) : (
            <div
              className={item.status === "unread" ? "avatar online" : "avatar"}
            >
              <span className="avatar-initial">
                {item.title ? item.title[0] : item.text[0]}
              </span>
            </div>
          )}
          <div className="list-group-body">
            <p>{item.title ? item.title : item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      );
    });
  
    return (
      <ul className="list-group">
        {notiList.length > 0 ? (
          notiList
        ) : (
          <li className="list-group-item">You don't have any messages yet</li>
        )}
      </ul>
    );
  }

  export default NotificationList;