import { Col } from "react-bootstrap";

export default function InactiveCampaignAlert({
    isCampaignInactive,
    isHTR,
    closedAlert,
    setClosedAlert,
}) {
    return (
        isCampaignInactive ? (
            isHTR ? (
              !closedAlert ? (
                <Col md="12">
                  <div className="fade alert alert-dismissible danger-system-alert show mb-0">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close alert"
                      onClick={() => setClosedAlert(true)}
                    ></button>
                    <div className="banner">
                      <h3 className="banner-title">
                        Your campaign is inactive at the moment.
                      </h3>
                      <p className="banner-text">
                        Please top-up and activate your campaign to start
                        bidding on PPC inventory
                      </p>
                    </div>
                  </div>
                </Col>
              ) : (
                <></>
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )
    );
}
