import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

const HTR_PUBLISHER_ID = 67;

export function YourBidCellRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    let bid = value.split("@")[0];
    const bids_amount = Number(value.split("@")[3]);
    const currentPos = Number(value.split("@")[1]);

    if (bid === "-") {
        bid = "No bid";
    }

    // todo: move it back for HTR prod
    if (currentPos > bids_amount && bid !== "No bid" && publisherIdByAbbr === HTR_PUBLISHER_ID) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <span className="text-danger">
                    {bid}
                </span>
                <i class="ri-error-warning-line text-secondary align-icon text-danger"></i>
            </div>
        )
    }

    return <div className="align-items-center">{bid}</div>;
}
 
export function YourBidCellColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_specific_column_headers.your_bid;
}

export function YourBidCellTooltipRenderer({ value }) {
    let bid = value.split("@")[0];
    const bids_amount = Number(value.split("@")[3]);
    const currentPos = Number(value.split("@")[1]);
    const top_bid = value.split("@")[4];

    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    if (bid === "-") {
        bid = "No bid";
    }

    // todo: move it back for HTR prod
    if (currentPos > bids_amount && bid !== "No bid") {
        const top_bid_amount = Number(top_bid.replace("$", ""));
        const bid_amount = Number(bid.replace("$", ""));
        const bid_increase = (top_bid_amount + 1) - bid_amount;
        return `Your bid isn't high enough to be in one of the top positions. Increase your bid to secure a top position and start generating clicks.`;
        //return `Your bid isn't high enough to be in one of the top positions. Increase your bid by at least $${bid_increase} to secure a top position and start generating clicks.`;
    }

    return taxonomy.tooltips.ppc_specific_column_inlines.your_bid;
}
 