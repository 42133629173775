import { deleteCampaign, deleteCustomer } from "../services/adv";
import { deleteInventoryCampaign, deleteUser } from "../services/micro";
import { deleteCategory, deleteInventory, deleteWebsite } from "../services/pub";
import { deleteApplication } from "../services/pub";
async function deleteRecord(id, table_name) {
    try {
        if (!table_name) {
            throw new Error("Table name is required");
        }
        
        let response;

        if (table_name === 'publisher_categories') {
            response = await deleteCategory(id);
        }   
        
        if (table_name === 'publisher_websites') {
            response = await deleteWebsite(id);
        }

        if (table_name === 'publisher_inventory') {
            response = await deleteInventory(id);
        }

        if (table_name === 'clients_accounts') {
            response = await deleteCustomer(id);
        }

        if (table_name === 'client_campaigns') {
            response = await deleteCampaign(id);
        }

        if (table_name === 'users') {
            response = await deleteUser(id);
        }

        if (table_name === 'inventory_campaigns') {
            response = await deleteInventoryCampaign(id);
        }

        if (table_name === 'client_applications') {
            response = await deleteApplication(id);
        }

        return response;
    } catch (error) {
        console.log(error);
    }
}

export default deleteRecord;
