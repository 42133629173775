import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

/**
 * Retrieves client applications based on the provided parameters.
 *
 * @param {Object} options - Request parameters.
 * @param {string} [options.search] - Search query.
 * @param {number} [options.page] - Page number.
 * @param {string} [options.column_name] - Column name for sorting.
 * @param {string} [options.column_order] - Sorting order (asc or desc).
 * @returns {Promise<Object>} - An object containing campaign data.
 * @throws {Error} - If an error occurs during the request.
 */
async function getClientApplications({
  search,
  page,
  column_name,
  column_order,
} = {}) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Token not found. Authorization cannot be performed.");
    }
    axiosInstance.defaults.headers.common["Authorization"] = token;

    const params = {};
    if (search) params.search = search;
    if (page) params.page = page;
    if (column_name) params.column_name = column_name;
    if (column_order) params.column_order = column_order;

    const response = await axiosInstance.get("/api/client-applications/all/", {
      params,
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching client applications data:", error);
    throw error;
  }
}

export default getClientApplications;
