export const processTableData = (tableData, hiddenColumns, colWidth, rowFields, customColumns = [], additionalInfo = []) => {
    if (!tableData?.table.columns) return { columns: [], data: [] };
    
    const columns = tableData.table.columns
        .filter((column) => column && column.status !== "INACTIVE")
        .map((column) => {

            const customColumn = customColumns?.find((custom) => custom.field === column.name);

            
            return {
                headerName: customColumn?.headerName || column.title,
                field: column.name,
                sortable: column.is_sortable,
                maxWidth: column.name.toUpperCase() === "ID" ? colWidth[column.name] : null,
                minWidth: colWidth[column.name],
                width: colWidth[column.name],
                hide: hiddenColumns?.includes(column.name),
                cellRenderer: customColumn?.cellRenderer ? (params) =>
                    customColumn.cellRenderer(params, additionalInfo.publisherIdByAbbr, additionalInfo.currentSkin)
                    : null,
            };
        });

    const data = tableData.data.map((row) => {
        let rowData = { id: row.id };
        rowFields.forEach((field) => {
            rowData[field] = row[field];
        });

        return rowData;
    });
    
    return { columns, data };
};
