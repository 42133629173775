import HTRBlankPage from "./BlankIframePopup/10/index.jsx";
import HTRProdBlankPage from "./BlankIframePopup/67/index.jsx";
import DefaultBlankPage from "./BlankIframePopup/default/index.jsx";
import netnetixLoader from "./loaders/34/index.js";
import htrLoader from "./loaders/10/index.js";
import HTRProdLoader from "./loaders/67/index.js";
import defaultLoader from "./loaders/default/index.js";
import htrLogo from "./logos/10/index.js";
import sftLogo from "./logos/68/index.js";
import HTRProdLogo from "./logos/67/index.js";
import logo from "./logos/34/index.js";
import defaultLogo from "./logos/default/index.js";
import defaultPlaceholderLogo from "./placeholderLogo/default/index.js";
import htrPlaceholderLogo from "./placeholderLogo/10/index.js";
import HTRProdPlaceholderLogo from "./placeholderLogo/67/index.js";
import netnetixPlaceholderLogo from "./placeholderLogo/34/index.js";

import DefaultAuctionCampaignStatusCard from "./AuctionCampaignStatusCard/default";
import DefaultAuctionCreditBalanceCard from "./AuctionCreditBalanceCard/default";
import DefaultAuctionMonthlyBudgetCardModule from "./AuctionMonthlyBudgetCard/default";

export const AuctionCampaignStatusCardMap = {
  0: DefaultAuctionCampaignStatusCard,
  10: DefaultAuctionCampaignStatusCard,
  67: DefaultAuctionCampaignStatusCard,
  34: DefaultAuctionCampaignStatusCard,
  68: DefaultAuctionCampaignStatusCard,
  73: DefaultAuctionCampaignStatusCard,
};

export const AuctionCreditBalanceCardMap = {
  0: DefaultAuctionCreditBalanceCard,
  10: DefaultAuctionCreditBalanceCard,
  67: DefaultAuctionCreditBalanceCard,
  34: DefaultAuctionCreditBalanceCard,
  68: DefaultAuctionCreditBalanceCard,
  73: DefaultAuctionCreditBalanceCard,
};

export const AuctionMonthlyBudgetCardMap = {
  0: DefaultAuctionMonthlyBudgetCardModule,
  10: DefaultAuctionMonthlyBudgetCardModule,
  67: DefaultAuctionMonthlyBudgetCardModule,
  34: DefaultAuctionMonthlyBudgetCardModule,
  68: DefaultAuctionMonthlyBudgetCardModule,
  73: DefaultAuctionMonthlyBudgetCardModule,
};

export const blankPageMap = {
  0: DefaultBlankPage(),
  10: HTRBlankPage(),
  67: HTRProdBlankPage(),
  34: DefaultBlankPage(),
  68: DefaultBlankPage(),
  73: DefaultBlankPage(),
};

export const logosMap = {
  0: defaultLogo,
  1: defaultLogo,
  10: htrLogo,
  34: logo,
  67: HTRProdLogo,
  68: sftLogo,
  73: logo,
};

export const placeholderLogoMap = {
  0: defaultPlaceholderLogo,
  1: defaultPlaceholderLogo,
  10: htrPlaceholderLogo,
  34: netnetixPlaceholderLogo,
  67: HTRProdPlaceholderLogo,
  73: netnetixPlaceholderLogo,
};

export const loadersMap = {
  0: defaultLoader(),
  1: defaultLoader(),
  10: htrLoader(),
  34: netnetixLoader(),
  67: HTRProdLoader(),
  73: netnetixLoader(),
};

export const abbreviationsMap = {
  rbx: 1,
  htr: 67,
  htrstage: 10,
  nnx: 34,
  sft: 68,
  apv: 73,
};
