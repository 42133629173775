import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../../../scss/components/_ag-table.scss";
import { getServerSideDatasourcePPC } from "../../../../../helpers/get-serverside-datasource-ppc";
import "ag-grid-charts-enterprise";
import {
  PositionRenderer,
  PositionCellTooltipRenderer,
  PositionColumnHeaderTooltipRenderer,
} from "./SpecificPositionRenderer";
import { StatusCellTooltipRenderer, StatusColumnHeaderTooltipRenderer, StatusRenderer } from "./SpecificStatusRenderer";
import { ActionCellColumnHeaderTooltipRenderer, ActionCellTooltipRenderer, ActionRenderer } from "./SpecificActionRenderer";
import {
  MissedClicksForecastCellColumnHeaderTooltipRenderer,
  MissedClicksForecastCellTooltipRenderer,
  MissedClicksForecastRenderer,
} from "./SpecificMissedClicksForecastRenderer";
import { NameRenderer, NameCellTooltipRenderer, NameColumnHeaderTooltipRenderer } from "./SpecificNameRenderer";
import {
  YourBidCellColumnHeaderTooltipRenderer,
  YourBidCellRenderer,
  YourBidCellTooltipRenderer,
} from "./SpecificYourBidRenderer";
import { TopBidCellColumnHeaderTooltipRenderer, TopBidCellRenderer, TopBidCellTooltipRenderer } from "./SpecificTopBidRenderer";
import { YourClicksCellColumnHeaderTooltipRenderer, YourClicksCellTooltipRenderer } from "./SpecificClicksRenderer";
import { CustomTooltip } from "../../CustomTooltip";
import { Card } from "react-bootstrap";
import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

const customColumns = [
  {
    field: "Name",
    cellRenderer: NameRenderer,
    headerTooltip: NameColumnHeaderTooltipRenderer(),
    tooltipValueGetter: NameCellTooltipRenderer
  },
  {
    field: "Position",
    cellRenderer: PositionRenderer,
    headerTooltip: PositionColumnHeaderTooltipRenderer(),
    tooltipValueGetter: PositionCellTooltipRenderer,
  },
  {
    field: "Status",
    cellRenderer: StatusRenderer,
    headerTooltip: StatusColumnHeaderTooltipRenderer(),
    tooltipValueGetter: StatusCellTooltipRenderer,
  },
  {
    field: "Your Bid",
    cellRenderer: YourBidCellRenderer,
    headerTooltip: YourBidCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: YourBidCellTooltipRenderer
  },
  {
    field: "Top Bid",
    cellRenderer: TopBidCellRenderer,
    headerTooltip: TopBidCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: TopBidCellTooltipRenderer
  },
  {
    field: "Your Clicks",
    headerTooltip: YourClicksCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: YourClicksCellTooltipRenderer,
  },
  {
    field: "Action",
    cellRenderer: ActionRenderer,
    headerTooltip: ActionCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: ActionCellTooltipRenderer
  },
  {
    field: "Missed Clicks Forecast",
    cellRenderer: MissedClicksForecastRenderer,
    headerTooltip: MissedClicksForecastCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: MissedClicksForecastCellTooltipRenderer,
  },
];

export const SpecificPPCAuctions = ({
  getData,
  skin,
  onClick,
  campaignId,
  updatePage,
  isHTR,
  display
}) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ minHeight: "100%", width: "100%" }), []);

  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);

  const [data, setData] = useState([]);

  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  useEffect(() => {
    getData({ search }, campaignId).then((response) => {
      if (response.success) {
        setData(response.data.rows);
      }
    });
  }, [campaignId]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      tooltipComponent: CustomTooltip,
    };
  }, []);

  const onFilterTextBoxChanged = (e) => {
    setSearch(e.target.value);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);

    const datasource = getServerSideDatasourcePPC(
      getData,
      setColumnDefs,
      search,
      customColumns,
      campaignId
    );

    setTimeout(() => {
      params.api.setGridOption("serverSideDatasource", datasource);
    }, 2000);
  }, [campaignId]);

  useEffect(() => {
    if (gridApi) {
      const datasource = getServerSideDatasourcePPC(
        getData,
        setColumnDefs,
        search,
        customColumns,
        campaignId
      );

      setTimeout(() => {
        gridApi.setGridOption("serverSideDatasource", datasource);
      }, 2000);
    }
  }, [gridApi, search, updatePage, campaignId]);

  if (display === false) {
    return <></>;
  }

  if (data?.length === 0) {
    return <></>;
  }

  return (
    <Card className="card-one">
      <Card.Header className="d-flex flex-column align-items-start">
        <Card.Title as="h6" className="mb-2">
          {isHTR && "Category Auctions"}
          {!isHTR && "Inventory Specific Auctions"}
        </Card.Title>
        { isHTR &&
          <Card.Subtitle as="p">
            Best for driving hyper targeted clicks in a specific category.
            <Card.Link
              target="_blank"
              className="ps-2"
              href="https://help.hoteltechreport.com/en/articles/10084263-understanding-the-types-of-auctions"
            >
              Learn more
            </Card.Link>
          </Card.Subtitle>
        }
      </Card.Header>
      <Card.Body>
        <div style={containerStyle}>
          <div
            style={gridStyle}
            className={skin === "dark" ? "ag-theme-quartz-dark ag-theme-quartz" : "ag-theme-quartz-light ag-theme-quartz"}
          >
            <div style={{ marginBottom: "10px" }}>
              <input
                type="text"
                className="input-search"
                placeholder="Search..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              pagination={true}
              tooltipShowDelay={500}
              tooltipHideDelay={200000}
              paginationPageSize={10}
              paginationPageSizeSelector={false}
              cacheBlockSize={10}
              rowModelType={"serverSide"}
              domLayout={"autoHeight"}
              suppressContextMenu={true}
              onCellClicked={onClick}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
