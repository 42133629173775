import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

const HTR_PUBLISHER_ID = 67;
const STAGE_HTR_PUBLISHER_ID = 10;

export function PositionRenderer({ value }) {
    if (!value) return null;

    const pubId = GetPubAbbr();

    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];

    if (campaign_active === "false") {
        return (
            <div className="align-items-center">
                Not shown
            </div>
        );
    }

    const bid = value.split("@")[8];
    if (currentPos > bids_amount || currentPos === 0) {
        // todo: move it back for HTR prod
        // if ((pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID) && bid !== "-") {
        //     return (
        //         <div className="d-flex align-items-center">
        //             <span>Outbid</span>
        //         </div>
        //     );
        // } else {
        //     currentPos = "Not shown";
        // }
        currentPos = "Not shown";
    }

    if (estimatedPos > bids_amount || estimatedPos === 0) {
        currentPos = "Not shown";
    }

    if (currentPos !== "Not shown") {
        currentPos = "#" + currentPos;
    }

    if (ppc_bid_active === "false" || campaign_active === "false" || ppc_paused === "true") {
        return (
            <div className="align-items-center">
                Not shown
            </div>
        );
    }

    if (pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID) {
        return (
            <div className="align-items-center">
                <span>{currentPos}</span>
            </div>
        );
    } else {
        return (
            <div className="align-items-center">
                <span>{currentPos}</span>
            </div>
        );
    }
}

export function PositionColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_branding_column_headers.position;
}

export function PositionCellTooltipRenderer({ value }) {
    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];

    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    if (bids_amount < currentPos) {
        let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_not_shown;

        return tooltip;
        // return `You are not generating clicks in the auction currently. Make sure you have credits on your account as well as remaining budget for the month and have bidding enabled to become visible in the ad unit and start generating clicks` // `You current position is not shown.`;
    }

    if (campaign_active === "false") {
        let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_not_shown;

        return tooltip;
        // return `Your campaign is not active. That's why your position is not shown.`;
    }

    if (ppc_bid_active === "false") {
        let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_not_shown;

        return tooltip;
        // return `You are not generating clicks in the auction currently. Make sure you have credits on your account as well as remaining budget for the month and have bidding enabled to become visible in the ad unit and start generating clicks`;
    }

    if (ppc_paused === "true" && campaign_active === "true") {
        let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_not_shown;

        return tooltip;
        // return `Your bidding is paused because you have reached your budget limit. That's why your position is not shown.`;
    }

    if (currentPos === 0) {
        let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_not_shown;

        return tooltip;
        // return `You are not generating clicks in the auction currently. Make sure you have credits on your account as well as remaining budget for the month and have bidding enabled to become visible in the ad unit and start generating clicks` // `Your current position is not shown.`;
    }

    if (currentPos <= bids_amount && currentPos > 0) {
        if (currentPos === 1) {
            let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_shown;
            tooltip = tooltip.replace("{position}", currentPos);
            return tooltip;
            // return `Your current position is #${currentPos}.`;
        } else {
            if (currentPos === estimatedPos) {
                let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_shown;
                tooltip = tooltip.replace("{position}", currentPos);
                return tooltip;
                // return `Your current position is #${currentPos}.`;
            }

            let tooltip = taxonomy.tooltips.ppc_branding_column_inlines.position.is_shown;
            tooltip = tooltip.replace("{position}", estimatedPos);
            return tooltip;
            // return `Your current position is #${currentPos}. But you can get to position #${estimatedPos} if you increase your bid.`;
        }
    }
}