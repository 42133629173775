import React, { useEffect, useRef, useState, useCallback } from "react";
import Select from "react-select";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { arrayMove } from "@dnd-kit/sortable";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { getClientCampaigns, getTableStructure, loadImages } from "../services/micro";
import createNewRecord from "../helpers/create-new-record";
import simpleTopList from "../assets/svg/simple_top_list.svg";
import topListOverview from "../assets/svg/top_list_overview.svg";
import simpleDynamicBanner from "../assets/svg/simple_dynamic_banner.svg";
import dynamic3PackBanner from "../assets/svg/dynamic_3pack_banner.svg";
import getMinCpc from "../services/micro/get_min_cpc";
import validateForRequired from "../helpers/validateForRequired";
import { getParentPublisherInventories } from "../services/pub";
import { FileUploadInput } from "../adv/campaign/components/FileUploadInput";
import ImageCropModal from "../adv/campaign/components/ImageCropModal";
import { validateImageDimensions } from "../helpers/validate-image-dimensions";
import MultipleFilesUploadInput from "../adv/campaign/components/MultipleFilesUploadInput";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";
import { getAllCampaigns } from "../services/adv";
import { getPublisherCategories } from "../helpers/get-publisher-categories";
import { getAllPubCampaings } from "../services/pub";

const STATUS_ACTIVE = "ACTIVE";
const STATUS_SHOULD_BE_LOADED = "SHOULD_BE_LOADED";

const PUBLISHER_INVENTORY = "publisher_inventory";
const TRANSACTIONS = "transactions";

const MANUAL_CREATION_TAB = "manual_creation";
const DEFAULT_CREATION_TAB = "default_creation";

export default function RecordCreate({
  table_name,
  title,
  redirect,
  breadcrumb,
  item_name,
}) {
  const [tableStructure, setTableStructure] = useState({});
  const [columnsValues, setColumnsValues] = useState({});
  const [parentInventories, setParentInventories] = useState([]);
  const [parentInventory, setParentInventory] = useState(null);
  const [data, setData] = useState({
    website_id: null,
    inventory_id: null,
    campaign_id: null,
  });
  const [minCpcValue, setMinCpcValue] = useState();
  const [boxTypes, setBoxTypes] = useState([]);
  const [validationResults, setValidationResults] = useState({});
  const [isInventoryTypeCluster, setIsInventoryTypeCluster] = useState(false);
  const [isInventoryTypeHomepage, setIsInventoryTypeHomepage] = useState(false);
  const user = useSelector((state) => state.user);
  const publisher_id = user.publisher_id;
  const [modalShow, setModalShow] = useState(false);
  const [imageFiles, setImageFiles] = useState(null);
  const logoInputRef = useRef(null);
  const screenshotsInputRef = useRef(null);
  const [croppedLogoPreview, setCroppedLogoPreview] = useState(null);
  const [croppedScreenshotsPreviews, setCroppedScreenshotsPreviews] = useState(
    []
  );
  const [croppedLogoFile, setCroppedLogoFile] = useState(null);
  const [croppedScreenshotFiles, setCroppedScreenshotFiles] = useState([]);
  const [ref, setRef] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 150, height: 150 });
  const [tab, setTab] = useState(DEFAULT_CREATION_TAB);
  const [campaigns, setCampaigns] = useState([]);
  const [categories, setCategories] = useState([]);

  const { cookiesData, getPathKey } = useCookiesContext();

  useEffect(() => {
    getTableStructure(table_name, publisher_id).then((res) => {
      setTableStructure(res);

      const columns = res.columns.reduce((acc, column) => {
        if (column.default_value) {
          acc[column.name] = column.default_value;
        } else {
          acc[column.name] = "";
        }
        return acc;
      }, {});

      setColumnsValues(columns);

      getParentPublisherInventories().then((d) => {
        if (d.success) {
          // add default inventory None
          d.data.unshift({
            id: 0,
            name: "None",
            value: null,
          });
          setParentInventories(d.data);
        }
      });

      getAllPubCampaings().then((d) => {
        if (d.success) {
          setCampaigns(d.data);
        }
      });
    });

    fetchCategories();
  }, []);

  useEffect(() => {
    updateCpcValue();
  }, [data]);

  const currentSkin =
    cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark"
      ? "dark"
      : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const redirectAfterSubmit = () => {
    window.location.href = redirect;
  };

  const onSubmit = async (e) => {
    let submitColumnsValues = { ...columnsValues };

    submitColumnsValues.publisher_id = publisher_id;
    submitColumnsValues.box_types = submitColumnsValues?.box_types
      ? submitColumnsValues.box_types
      : [];
    submitColumnsValues.related_categories_ids =
      submitColumnsValues?.related_categories_ids
        ? submitColumnsValues.related_categories_ids
        : [];
    submitColumnsValues.website_id = submitColumnsValues.website_id?.value
      ? submitColumnsValues.website_id?.value
      : submitColumnsValues.website_id;

    await Promise.all(
      Object.keys(submitColumnsValues).map(async (key) => {
        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "date"
        ) {
          submitColumnsValues[key] = new Date(
            submitColumnsValues[key]
          ).getTime();
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "string"
        ) {
          submitColumnsValues[key] = String(submitColumnsValues[key]);
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "number"
        ) {
          submitColumnsValues[key] = Number(submitColumnsValues[key]);
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "boolean"
        ) {
          submitColumnsValues[key] = Boolean(submitColumnsValues[key]);
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "text"
        ) {
          submitColumnsValues[key] = String(submitColumnsValues[key]);
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "list"
        ) {
          submitColumnsValues[key] = submitColumnsValues[key]
            .split(",")
            .map((item) => item.trim());
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "selector"
        ) {
          // submitColumnsValues[key] = submitColumnsValues[key].value;
          const column = tableStructure.columns.find(
            (column) => column.name === key
          );
          const value =
            column.options.find(
              (option) => option.label === submitColumnsValues[key].label
            )?.value ??
            column.options.find(
              (option) => option.value === submitColumnsValues[key]
            )?.value;

          submitColumnsValues[key] = value;
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "multiselector"
        ) {
          const column = tableStructure.columns.find(
            (column) => column.name === key
          );

          if (key === "related_categories_ids" && isInventoryTypeHomepage) {
            const allValues = tableStructure.columns
              .find((column) => column.name === key)
              .options.map((option) => option.value);

            submitColumnsValues[key] = allValues;
            return;
          }

          if (submitColumnsValues[key]?.length) {
            const values = submitColumnsValues[key]?.map((value) => {
              return column.options.find((option) => option.label === value)
                ?.value;
            });
            submitColumnsValues[key] = values;
          } else {
            submitColumnsValues[key] = [];
          }
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "files"
        ) {
          if (croppedScreenshotFiles.length > 0) {
            const screenshotIds = [];
            for (const croppedFile of croppedScreenshotFiles) {
              if (typeof croppedFile !== "number") {
                const screenshotResponse = await loadImages([croppedFile]);
                if (screenshotResponse?.success) {
                  screenshotIds.push(Number(screenshotResponse.data[0].id));
                }
              } else {
                screenshotIds.push(croppedFile);
              }
            }
            submitColumnsValues[key] = screenshotIds;
          } else {
            submitColumnsValues[key] = [];
          }
        }

        if (
          tableStructure.columns.find((column) => column.name === key)?.type ===
          "file"
        ) {
          if (croppedLogoFile) {
            const logoResponse = await loadImages([croppedLogoFile]);
            if (logoResponse?.success) {
              submitColumnsValues[key] = Number(logoResponse.data[0].id);
            }
          } else {
            submitColumnsValues[key] = null;
          }
        }
      })
    );

    let newRecord = tableStructure.create_columns.reduce((acc, column) => {
      acc[column] = submitColumnsValues[column];
      return acc;
    }, {});

    const isManualCreation =
      table_name === TRANSACTIONS && tab === MANUAL_CREATION_TAB;
    const isValid = await validateForRequired(
      tableStructure,
      newRecord,
      isManualCreation
    );

    if (isValid.vat_number === false) {
      toast.error("VAT number is not valid!");
    }

    setValidationResults(isValid);

    if (table_name === PUBLISHER_INVENTORY && parentInventory) {
      const newParentInventory = parentInventories.find(
        (item) => item.id === parentInventory.value
      );
      newRecord.publisher_inventory_parent_id = newParentInventory.id;
      const newHirarchyLevel = newParentInventory.inventory_hirarchy_level + 1;
      newRecord.inventory_hirarchy_level = newHirarchyLevel;
    }

    if (Object.values(isValid).every((result) => result)) {
      toast.success("Record creating...");

      setTimeout(() => {
        createNewRecord(tableStructure.db_name, newRecord)
          .then((res) => {
            if (res.success) {
              toast.success("Record created successfully!");
              redirectAfterSubmit();
            } else {
              toast.error("Record creating failed!");
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("You don't have permission to create this record!");
          });
      }, 3000);
    }
  };

  const handleParentInventoryChange = (e) => {
    setParentInventory(e);
  };

  const handleOnblur = (e, column) => {
    e.target.value = roundToMinIncrement(e, column);
  };

  const insertMinCpcOrDefaultValue = (column) => {
    // If there is a value in the state, return it (prevent negative values)
    if (
      columnsValues.hasOwnProperty(column.name) && 
      columnsValues[column.name] !== "" &&
      Number(columnsValues[column.name]) >= 0
    ) {
      return columnsValues[column.name];
    }
  
    // If min_cpc or cpc, return default value, if it is (prevent negative default values)
    if ((column.name === "min_cpc" || column.name === "cpc") && minCpcValue && Number(minCpcValue) >= 0) {
      return Number(minCpcValue)
    }

    if (table_name === TRANSACTIONS) {
      return Number(columnsValues[column.name])
    }
  
    return "";
  };
  const handleRemoveScreenshot = (index) => {
    setCroppedScreenshotsPreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);

      return updatedPreviews;
    });

    const newFiles = croppedScreenshotFiles.filter((_, i) => i !== index);

    if (newFiles.length === 0) {
      document.getElementById("screenshots-input-placeholder").innerText =
        "No files selected";
    }

    setCroppedScreenshotFiles(newFiles);
  };

  // If column have min_increment property, it will be rounded, else- return current value
  const roundToMinIncrement = (e, column) =>
    column.min_increment
      ? Math.round(Number(e.target.value) / column.min_increment) *
        column.min_increment
      : Number(e.target.value);

  const handleColumnChange = async (e, column) => {
    let columnForChange = { ...columnsValues };

    if (column.type === "boolean") {
      columnForChange[column.name] = e.target.checked;
    } else if (column.type === "string") {
      columnForChange[column.name] = String(e.target.value);
    } else if (column.type === "text") {
      columnForChange[column.name] = String(e.target.value);
    } else if (column.type === "date") {
      columnForChange[column.name] = new Date(e.target.value).getTime();
    } else if (column.type === "number") {
      console.log("TABLE NAME", table_name);
      console.log("COLUMN NAME", column.name);
      console.log("E", e.target.value);
      if (table_name === TRANSACTIONS) {
        if (Number(e.target.value) > 0) {
          columnForChange[column.name] = roundToMinIncrement(e, column)
        } else {
          console.log("E.TARGET.VALUE", e.target.value);
          columnForChange[column.name] = Number(e.target.value)
        }
      } else {
        columnForChange[column.name] = roundToMinIncrement(e, column)
      }
      // if (e.target.value > 0) {
      //   columnForChange[column.name] = roundToMinIncrement(e, column)
      // } else {
      //   columnForChange[column.name] = 0
      // }
    } else if (column.type === "list") {
      columnForChange[column.name] = e.target.value
        .split(",")
        .map((item) => item.trim());
    } else if (column.type === "selector") {
      if (column.name === "inventory_type" && e.value === 3) {
        setIsInventoryTypeHomepage(true);
      } else if (column.name === "inventory_type" && e.value !== 3) {
        setIsInventoryTypeHomepage(false);
      }

      if (column.name === "inventory_type" && e.value === 5) {
        setIsInventoryTypeCluster(true);
      } else if (column.name === "inventory_type" && e.value !== 5) {
        setIsInventoryTypeCluster(false);
      } else if (column.name === "main_category") {
        columnForChange[column.name] = e.label;
      }

      columnForChange[column.name] = e.label ? e : null;
    } else if (column.type === "files") {
      const files = e.target.files;
      const totalScreenshots = croppedScreenshotFiles.length + files.length;

      if (files.length > 0 && totalScreenshots <= 4) {
        const newFiles = Array.from(files);
        const screenshotsDimensions = { width: 1600, height: 900 };
        const { validFiles } = await validateImageDimensions(
          newFiles,
          screenshotsDimensions
        );

        if (validFiles.length > 0) {
          setDimensions(screenshotsDimensions);
          setRef(screenshotsInputRef);
          setImageFiles(validFiles);
          setModalShow(true);
        }

        e.target.value = null;
      } else if (totalScreenshots > 4) {
        e.target.value = null;
        toast.error("You can upload a maximum of 4 files in total!");
      } else {
        e.target.value = null;
      }
    } else if (column.type === "file") {
      if (e.target.files.length > 0) {
        const files = Array.from(e.target.files);
        const logoDimensions = { width: 150, height: 150 };

        const { validFiles } = await validateImageDimensions(
          files,
          logoDimensions
        );

        if (validFiles.length > 0) {
          setRef(logoInputRef);
          setImageFiles(validFiles);
          setDimensions(logoDimensions);
          setModalShow(true);
        }

        e.target.value = null;
      } else columnForChange[column.name] = null;
    } else if (column.type === "multiselector") {
      if (e.length) {
        const eventValues = e.map((value) => value.label);
        columnForChange[column.name] = eventValues;
      } else {
        columnForChange[column.name] = [];
      }

      if (column.name === "box_types") {
        const boxTypes = e.map((elem) => elem.value);
        setBoxTypes(boxTypes);
      }
    } else {
      columnForChange[column.name] = e.target.value;
    }

    setColumnsValues(columnForChange);

    if (column.name === "inventory_id") {
      setData({ ...data, inventory_id: e });
    }
    if (column.name === "campaign_id") {
      setData({ ...data, campaign_id: e });
    }
    if (column.name === "website_id") {
      setData({ ...data, website_id: e });
    }

    // const newTableColumnsStructure = tableStructure.columns.map((column) => {
    //   column.value = columnForChange[column.name];
    //   return column;
    // });

    // setTableStructure({ ...tableStructure, columns: newTableColumnsStructure });
  };

  async function updateCpcValue() {
    const newMinCpcValue = await getMinCpc(
      table_name,
      data.website_id?.value,
      data.inventory_id?.value,
      data.campaign_id?.value
    );
    setMinCpcValue(newMinCpcValue?.data);
  }

  const modalHandleClose = () => setModalShow(false);

  const handleSaveCroppedImages = (croppedFile, index) => {
    const objectUrl = URL.createObjectURL(croppedFile);

    if (ref === logoInputRef) {
      setCroppedLogoFile(croppedFile);
      setCroppedLogoPreview(objectUrl);
      document.getElementById("logo-input-placeholder").innerText =
        "Logo loaded";
    } else if (ref === screenshotsInputRef) {
      setCroppedScreenshotsPreviews((prevPreviews) => {
        const updatedPreviews = [...prevPreviews, objectUrl];

        return updatedPreviews;
      });

      setCroppedScreenshotFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, croppedFile];

        return updatedFiles;
      });

      document.getElementById("screenshots-input-placeholder").innerText =
        "Screenshots loaded";
    }
  };

  const handleDragEnd = (event) => {
    if (!croppedScreenshotsPreviews.length) {
      return;
    }

    const { active, over } = event;
    const oldIndex = croppedScreenshotsPreviews.indexOf(active?.id);
    const newIndex = croppedScreenshotsPreviews.indexOf(over?.id);

    if (oldIndex === -1 || newIndex === -1) {
      return;
    }

    const newPreviews = arrayMove(
      croppedScreenshotsPreviews,
      oldIndex,
      newIndex
    );
    const newFiles = arrayMove(croppedScreenshotFiles, oldIndex, newIndex);
    setCroppedScreenshotsPreviews(newPreviews);
    setCroppedScreenshotFiles(newFiles);
  };

  const changeTab = (tab) => {
    setTab(tab);
  };

  const isAccountIdSelected =
    columnsValues?.account_id && columnsValues?.account_id !== "";

  const fetchCategories = useCallback(async () => {
    try {
      const token = cookiesData[getPathKey()][CookiesKeyNames.token];
      const publisherId =
        cookiesData[getPathKey()][CookiesKeyNames.publisherId];
      const response = await getPublisherCategories(token, publisherId);
      if (response.success) {
        const categories = response.data.map((category) => ({
          label: category.name,
          value: category.id,
        }));

        setCategories(categories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
    }
  }, [cookiesData, getPathKey]);
  
  return (
    <React.Fragment>
      <ImageCropModal
        modalShow={modalShow}
        modalHandleClose={modalHandleClose}
        imageFiles={imageFiles}
        onSave={handleSaveCroppedImages}
        dimensions={dimensions}
        ref={ref}
      />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <Nav.Item>
                <Nav.Link
                  className="text-primary"
                  href="/pub/dashboard/revenue"
                  to="/pub/dashboard/revenue"
                >
                  PUB Home
                </Nav.Link>
              </Nav.Item>
              <span className="mx-2">/</span>
              <Nav.Item>
                <Nav.Link className="text-primary" href={redirect}>
                  {breadcrumb}
                </Nav.Link>
              </Nav.Item>
              <span className="mx-2">/</span>
              <Nav.Item>
                <Nav.Link href="#">New</Nav.Link>
              </Nav.Item>
            </ol>
            <h2 className="main-title mb-0">{title}</h2>
          </div>
        </div>

        <Row className="g-3 justify-content-center">
          {table_name === TRANSACTIONS && (
            <Nav className="nav-line mb-0 ml-4 mt-5">
              {
                <Nav.Link
                  href="#default-creation"
                  className={tab === DEFAULT_CREATION_TAB ? "active" : ""}
                  onClick={() => changeTab(DEFAULT_CREATION_TAB)}
                >
                  Invoice Creation
                </Nav.Link>
              }
              {
                <Nav.Link
                  href="#manual-correction"
                  className={tab === MANUAL_CREATION_TAB ? "active" : ""}
                  onClick={() => changeTab(MANUAL_CREATION_TAB)}
                >
                  Balance Correction
                </Nav.Link>
              }
            </Nav>
          )}
          <Col md="12">
            <Row>
              <Col sm="12" md="6">
                <Card className="card-settings mt-4">
                  <Card.Body className="p-0">
                    {tableStructure &&
                      tableStructure.columns &&
                      tableStructure.columns.map((column, index) => {
                        if (
                          tableStructure.create_columns.find(
                            (create_column) => create_column === column.name
                          )
                        ) {
                          if (
                            table_name === TRANSACTIONS &&
                            tab === MANUAL_CREATION_TAB &&
                            (column.name === "user_email" ||
                              column.name === "first_name" ||
                              column.name === "last_name" ||
                              column.name === "invoice_account_name" ||
                              column.name === "invoice_address" ||
                              column.name === "invoice_street" ||
                              column.name === "invoice_city" ||
                              column.name === "invoice_address_zip_code" ||
                              column.name === "invoice_address_state" ||
                              column.name === "invoice_address_country_id" ||
                              column.name === "vat_number")
                          ) {
                            return <></>;
                          }

                          switch (column.type) {
                            case "text":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>{column.is_required && "Required"}</p>
                                    </Col>
                                    <Col md>
                                      <Form.Control
                                        type="text"
                                        placeholder={column.placeholder}
                                        onChange={(e) =>
                                          handleColumnChange(e, column)
                                        }
                                        value={columnsValues[column.name]}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "string":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>{column.is_required && "Required"}</p>
                                    </Col>
                                    <Col md>
                                      <Form.Control
                                        type="text"
                                        placeholder={column.placeholder}
                                        onChange={(e) =>
                                          handleColumnChange(e, column)
                                        }
                                        value={columnsValues[column.name]}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "list":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>
                                        {column.title} (separated by comma)
                                      </h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <Col md>
                                      <Form.Control
                                        type="text"
                                        placeholder={column.placeholder}
                                        onChange={(e) =>
                                          handleColumnChange(e, column)
                                        }
                                        value={columnsValues[column.name]}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "file":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <FileUploadInput
                                      onLogoChange={(e) =>
                                        handleColumnChange(e, column)
                                      }
                                      logoInputRef={logoInputRef}
                                      croppedLogoPreview={croppedLogoPreview}
                                    />
                                  </Row>
                                </div>
                              );
                            case "files":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <MultipleFilesUploadInput
                                      onScreenshotsChange={(e) =>
                                        handleColumnChange(e, column)
                                      }
                                      screenshotsInputRef={screenshotsInputRef}
                                      croppedScreenshotsPreviews={
                                        croppedScreenshotsPreviews
                                      }
                                      handleRemoveScreenshot={
                                        handleRemoveScreenshot
                                      }
                                      handleDragEnd={handleDragEnd}
                                    />
                                  </Row>
                                </div>
                              );
                            case "boolean":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <Col md>
                                      <Form.Check
                                        type="switch"
                                        label={column.placeholder}
                                        onChange={(e) =>
                                          handleColumnChange(e, column)
                                        }
                                        checked={columnsValues[column.name]}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "date":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <Col md>
                                      <Form.Control
                                        type="date"
                                        placeholder={column.placeholder}
                                        onChange={(e) =>
                                          handleColumnChange(e, column)
                                        }
                                        value={columnsValues[column.name]}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "number":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <Col md>
                                      <Form.Control
                                        type="number"
                                        placeholder={column.placeholder}
                                        onChange={(e) =>
                                          handleColumnChange(e, column)
                                        }
                                        onBlur={(e) => handleOnblur(e, column)}
                                        value={insertMinCpcOrDefaultValue(
                                          column
                                        )}
                                        step={column.min_increment ?? 1}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "selector":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>{column.is_required && "Required"}</p>
                                    </Col>
                                    <Col md>
                                      { // Campaign selector info
                                        (
                                          column.name === "campaign_id" && 
                                          table_name === TRANSACTIONS &&
                                          !isAccountIdSelected
                                        ) &&
                                          <Select
                                            id={`basic-select-single`}
                                            value={{
                                              label:
                                                "Please select account first",
                                              value: "",
                                            }}
                                            isDisabled={true}
                                          />
                                      }
                                      { // Campaign selector
                                        (
                                          column.name === "campaign_id" && 
                                          table_name === TRANSACTIONS &&
                                          isAccountIdSelected
                                        ) &&
                                          <Select
                                            id="basic-select-single"
                                            onChange={(e) => {
                                              handleColumnChange(e, column);
                                            }}
                                            options={campaigns.filter(campaign => {
                                              return campaign.account_id == columnsValues["account_id"].value
                                            }).map((option) => ({
                                              label: option.name,
                                              value: option.id,
                                            }))}
                                            placeholder={column.placeholder}
                                            is
                                            value={[columnsValues[column.name]]}
                                          />
                                      }
                                      { // Website selector
                                        column.name === "website_id" && isInventoryTypeCluster &&
                                          <Select
                                            id={`basic-select-single`}
                                            value={{
                                              label: "All websites",
                                              value:
                                                "All websites are associated to inventory by default",
                                            }}
                                            isDisabled={true}
                                          />
                                      }
                                      { // Website selector info
                                        column.name === "website_id" && isInventoryTypeCluster ?
                                          <div className="d-flex align-items-center fs-xs text-secondary mb-0 mt-1">
                                            <i className="ri-information-line me-1"></i>
                                            All websites are associated to inventory by default
                                          </div>
                                          :
                                          <></>
                                      }
                                      { // Any other selector
                                        (
                                          !isInventoryTypeCluster &&
                                          // column.name !== "website_id" &&
                                          (
                                            column.name !== "campaign_id" &&
                                            table_name !== TRANSACTIONS
                                          )
                                        ) &&
                                        (
                                          <Select
                                            id="basic-select-single"
                                            onChange={(e) => {
                                              handleColumnChange(e, column)
                                            }
                                            }
                                            options={column.options.map((option) => ({
                                              label: option.label,
                                              value: option.value,
                                            }))}
                                            placeholder={column.placeholder}
                                            is
                                            value={[columnsValues[column.name]]}
                                          />
                                        )
                                      }
                                      { // Any other selector
                                        (
                                          isInventoryTypeCluster &&
                                          column.name !== "website_id" &&
                                          (
                                            column.name !== "campaign_id" &&
                                            table_name !== TRANSACTIONS
                                          )
                                        ) &&
                                        (
                                          <Select
                                            id="basic-select-single"
                                            onChange={(e) => {
                                              handleColumnChange(e, column)
                                            }
                                            }
                                            options={column.options.map((option) => ({
                                              label: option.label,
                                              value: option.value,
                                            }))}
                                            placeholder={column.placeholder}
                                            is
                                            value={[columnsValues[column.name]]}
                                          />
                                        )
                                      }
                                      { // Any other selector
                                        (
                                          (
                                            column.name !== "campaign_id" &&
                                            table_name === TRANSACTIONS
                                          )
                                        ) &&
                                        (
                                          <Select
                                            id="basic-select-single"
                                            onChange={(e) => {
                                              handleColumnChange(e, column);
                                            }}
                                            options={column.options.map(
                                              (option) => ({
                                                label: option.label,
                                                value: option.value,
                                              })
                                            )}
                                            placeholder={column.placeholder}
                                            is
                                            value={[columnsValues[column.name]]}
                                          />
                                        )}
                                    </Col>
                                  </Row>
                                </div>
                              );
                            case "multiselector":
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <Col md>
                                      {
                                        column.name === "related_categories_ids" && isInventoryTypeHomepage ? (
                                          <Select
                                            id={`basic-select-single`}
                                            value={{
                                              label: "All categories",
                                              value:
                                                "All categories are associated to inventory by default",
                                            }}
                                            isDisabled={true}
                                          />
                                        ) : (
                                          <Select
                                            id="basic-multiselect"
                                            onChange={(e) =>
                                              handleColumnChange(e, column)
                                            }
                                            options={column.options?.map(
                                              (option) => {
                                                return {
                                                  label: option.label,
                                                  value: option.value,
                                                }
                                              })}
                                              isSearchable={true}
                                              placeholder={column.placeholder}
                                              selected={[columnsValues[column.name]]}
                                              isMulti
                                            />
                                        )
                                      }
                                      {
                                        column.name === "related_categories_ids" && isInventoryTypeHomepage ?
                                          <div className="d-flex align-items-center fs-xs text-secondary mb-0 mt-1">
                                            <i className="ri-information-line me-1"></i>
                                            All categories are associated to this inventory type by default
                                          </div>
                                          :
                                          <></>
                                      }
                                    </Col>
                                  </Row>
                                </div>
                              );
                            default:
                              return (
                                <div className="setting-item" key={index}>
                                  <Row className="g-2 align-items-center">
                                    <Col md="3">
                                      <h6>{column.title}</h6>
                                      <p>
                                        {column.is_required ? "Required" : ""}
                                      </p>
                                    </Col>
                                    <Col md>
                                      <Form.Control
                                        type="text"
                                        placeholder={column.placeholder}
                                        isInvalid={
                                          validationResults[column.name] ===
                                          false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                          }
                        } else {
                          return <></>;
                        }
                      })}
                      
                    {table_name === PUBLISHER_INVENTORY &&
                      columnsValues["inventory_type"]?.value == "5" && (
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Parent Inventory</h6>
                              <p>
                                Specify the parent inventory for this inventory
                              </p>
                            </Col>
                            <Col md>
                              <Select
                                id="basic-select-single-parent-inventory"
                                onChange={(e) => handleParentInventoryChange(e)}
                                options={parentInventories.map((option) => ({
                                  label: option.name,
                                  value: option.id,
                                }))}
                                placeholder="Select Parent Inventory"
                                value={parentInventory}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                    <Col md="12">
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          onClick={() => onSubmit()}
                          variant="outline-primary m-4"
                          size="sm"
                        >
                          Create
                        </Button>
                      </div>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              {/* Special Case for Publisher Inventory */}
              {table_name === "publisher_inventory" && (
                <Col xs="12" sm="6" lg="3" xl="3">
                  {boxTypes?.includes(1) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={simpleTopList}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Simple Top List
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          1 embed block
                        </Card.Subtitle>
                        <Card.Text>
                          Simple Top List block with custom number of products.
                          Compatible with live or interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  {boxTypes?.includes(2) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={topListOverview}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Top List + Product Overview
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          2 embed blocks
                        </Card.Subtitle>
                        <Card.Text>
                          Simple Top List & Overview blocks that stay in sync.
                          Compatible with live or interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  {boxTypes?.includes(3) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={simpleDynamicBanner}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Simple Dynamic Banner
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          1 embed block
                        </Card.Subtitle>
                        <Card.Text>
                          Simple Dynamic Banner block. Compatible with live or
                          interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  {boxTypes?.includes(4) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={dynamic3PackBanner}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Multi-Item Dynamic Banner
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          1 embed block
                        </Card.Subtitle>
                        <Card.Text>
                          Dynamic Multi-Item Banner blocks. Compatible with live
                          or interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}