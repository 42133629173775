import { Button } from "react-bootstrap";
export default function InactiveCampaignWarning({
    isDefault,
    campaign,
    handleCampaignActivate,
}) {
    return (
        isDefault && !campaign?.ppc_active && (
            <div className="alert alert-danger">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <strong>Warning!</strong> Your campaign is currently inactive.
                  Please activate your campaign to start bidding on PPC
                  inventory.
                </div>
                <Button variant="danger" onClick={handleCampaignActivate}>
                  Activate Campaign
                </Button>
              </div>
            </div>
          )
    )
}