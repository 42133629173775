import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function generateUserTokenByAdmin(api_key, user_id) {
  try {
    const token = getToken();
    
    const response = await axiosInstance.post(
      `/api/auth/generate-user-token-by-admin`,
      {
        api_key,
        user_id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error; // Re-throw the error for further handling if needed
  }
}

async function getUserAccount(account_id) {
  try {
    const token = getToken(account_id);
    const response = await axiosInstance.get(
      `/api/clients-accounts/user/${account_id}/account`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error; // Re-throw the error for further handling if needed
  }
}

export { generateUserTokenByAdmin, getUserAccount };
