import React, { useEffect, useState } from "react";
import { Button, Card, CardFooter, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import "react-country-state-city/dist/react-country-state-city.css";
import { toast } from "react-toastify";
import Header from "../layouts/Header";
import { getUserRole } from "../helpers/user-role";
import { validateVatNumber } from "../services/micro";
import createBillingMethod from "../services/adv/create_billing_method";
import { GetPubAbbr } from "../helpers/get-pub-abbr";
import getAllCountires from "../services/adv/get_all_countires";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import CompletePage from "./CompletePage";
import createPaymentIntent from "../services/micro/create_payment_intent";
import { getPublisher } from "../services/pub";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

const stripePromise = loadStripe('pk_test_51Pg7bJCXS8Gk0w4dnCr9QbBv2p9YKlwxNLMZBXdXyvcyCc5DP1DtByhQaD43U8ZzDlLXw3ZjC37mtoqxKFIL1Fjx00DI4AaE75');

const InvoiceTopUp = 1;
const InvoicePostPayment = 2;
const CreditCardTopUp = 3;
const CreditCardPostPayment = 4;

export default function BillingForm() {
    const budget = new URLSearchParams(window.location.search).get('budget');
    const campaignId = new URLSearchParams(window.location.search).get('campaignId');
    const billingMethod = new URLSearchParams(window.location.search).get('billingMethod');

    const [clientSecret, setClientSecret] = useState("");
    const [dpmCheckerLink, setDpmCheckerLink] = useState("");
    const { cookiesData, getPathKey } = useCookiesContext();


    // const appearance = { /* appearance */ };
    // const options = { /* options */ };
    // const elements = stripe.elements({ clientSecret, appearance });
    // const paymentElement = elements.create('payment', options);
    // paymentElement.mount('#payment-element');

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] ? 'dark' : '';
    const [setSkin] = useState(currentSkin);
    const user = useSelector((state) => state.user);
    const [country, setCountry] = useState({ name: null, code: null });
    const [publisherCountry, setPublisherCountry] = useState({ name: null, code: null });
    const [vatNumber, setVatNumber] = useState(null);
    const [isVatNumberValid, setIsVatNumberValid] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [isContactNameValid, setIsContactNameValid] = useState(null);

    const [countries, setCountries] = useState([]);

    const [email, setEmail] = useState("");
    const [contactName, setContactName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [billingAddress, setBillingAddress] = useState({
        line1: "",
        line2: "",
        postCode: "",
        town: "",
        country: "",
        region: ""
    });

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const countries = await getAllCountires();
                setCountries(countries.data);

                const publisherIdByCookies = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];

                getPublisher(
                    Number(publisherIdByCookies)
                ).then((response) => {
                    if (response.success) {
                        const publisher = response.data;
                        const country = Number(publisher.country_id);

                        setPublisherCountry({ 
                            name: countries.data.find((c) => c.id == country).name,
                            code: countries.data.find((c) => c.id == country).code
                        });
                    } else {
                        toast.error('Cannot get publisher');
                    }
                }).catch((error) => {
                    console.error("Error getting publisher:", error);
                });
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        }

        fetchCountries();
    }, []);

    useEffect(() => {
        createPaymentIntent({ 
            items: [{ id: "xl-tshirt", amount: 1000 }] 
        })
          .then((data) => { 
            setClientSecret(data.clientSecret);
            setCustomerId(data.customer);
            // [DEV] For demo purposes only
            setDpmCheckerLink(data.dpmCheckerLink);
          }).catch((error) => {
            console.error("Error creating payment intent:", error);
          });
    }, []);
    
    const [emptyFieldsValidation, setEmptyFieldsValidation] = useState(true)

    const handleContactNameChange = (e) => {
        const value = e.target.value;

        const lettersOnlyRegex = /^[A-Za-z\s]*$/;

        if (lettersOnlyRegex.test(value)) {
            setContactName(value);
            setIsContactNameValid(true);
        } else {
            setContactName(value);
            setIsContactNameValid(false);
        }
    };

    const checkFormValues = (obj) => {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'object' && value !== null) {
                if (key !== 'billingAddress') {
                    checkFormValues(value);
                } else {
                    for (const [nestedKey, nestedValue] of Object.entries(value)) {
                        if (!nestedValue && nestedKey !== 'line2') {
                            return false
                        }
                    }
                }
            } else if (!value && key !== 'line2') {
                return false
            }
        }
        return true;
    };


    const submitForm = () => {
        const formValues = {
            contactName,
            companyName,
            billingAddress: {
                ...billingAddress,
                country: country.name,
            },
            vatNumber,
            email,
        }

        if (!vatNumber) {
            delete formValues.vatNumber;
            setIsVatNumberValid(true);
        }

        const emptyCheck = checkFormValues(formValues); // returns true if all neccessary fields are filled

        setEmptyFieldsValidation(emptyCheck);

        if (emptyCheck && isVatNumberValid &&
            isEmailValid &&
            isContactNameValid) {

            if (billingMethod == CreditCardTopUp || billingMethod == CreditCardPostPayment) {
                createBillingMethod({
                    ppc_billing_type: Number(billingMethod),
                    invoice_cc_emails: [email],
                    billing_contact_name: contactName,
                    billing_company_name: companyName,
                    billing_registered_address: billingAddress.line1,
                    additional_billing_registered_address: billingAddress.line2,
                    billing_registered_address_zip_code: billingAddress.postCode,
                    billing_registered_address_city: billingAddress.town,
                    billing_registered_address_country_id: countries
                        .find((c) => c.code === country.code).id,
                    billing_registered_address_state: billingAddress.region,
                    billing_vat_number: vatNumber,
                    stripe_customer_id: clientSecret,
                    stripe_payment_method_id: clientSecret,
                    campaign_id: Number(campaignId),
                }).then((response) => {
                    toast.success("Billing method created successfully.");
                    window.location.href = `/adv/campaign/activate?campaignId=${campaignId}&budget=${budget}&contactName=${contactName}`;
                }).catch((error) => {
                    toast.error("An error occurred while creating billing method.");
                });
            } else {
                createBillingMethod({
                    ppc_billing_type: Number(billingMethod),
                    invoice_cc_emails: [email],
                    billing_contact_name: contactName,
                    billing_company_name: companyName,
                    billing_registered_address: billingAddress.line1,
                    additional_billing_registered_address: billingAddress.line2,
                    billing_registered_address_zip_code: billingAddress.postCode,
                    billing_registered_address_city: billingAddress.town,
                    billing_registered_address_country_id: countries
                        .find((c) => c.code === country.code).id,
                    billing_registered_address_state: billingAddress.region,
                    billing_vat_number: vatNumber,
                    campaign_id: Number(campaignId),
                }).then((response) => {
                    toast.success("Billing method created successfully.");
                    window.location.href = `/adv/campaign/activate?campaignId=${campaignId}&budget=${budget}&contactName=${contactName}`;
                }).catch((error) => {
                    toast.error("An error occurred while creating billing method.");
                });
            }
        } else {
            toast.error("Please fill in all required fields.");
        }
    }

    useEffect(() => {
        if (country.code === null || vatNumber === null) {
            return;
        }

        const validateVAT = async () => {
            try {
                const vatValidation = await validateVatNumber({ countryCode: country.code, vatNumber: vatNumber });

                if (vatValidation.data.isVatValid) {
                    setIsVatNumberValid(true);
                } else {
                    setIsVatNumberValid(false);
                }
            } catch (error) {
                console.error("Error validating VAT number:", error);
            }
        };

        validateVAT();
    }, [country, vatNumber]);

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailRegex.test(value));
    };

    const formValues = {
        contactName,
        companyName,
        billingAddress: {
            ...billingAddress,
            country: country.name,
        },
        vatNumber,
        email,
    }

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="/adv/home">ADV Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page"><Link to={`/adv/campaign/ppc?campaignId=${
                                campaignId
                            }`}>PPC Auctions</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">New Billing Method</li>
                        </ol>
                        <h2 className="main-title mb-0">
                            New Billing Method
                        </h2>
                    </div>
                </div>

                <Col md="12" xl="6">
                    <Card className="card-settings mt-3">
                        <Card.Header>
                            <Card.Title className="mb-0">{
                                (
                                    billingMethod == InvoiceTopUp || billingMethod == InvoicePostPayment
                                ) ? "Billing Method" : "Billing Details"
                            }</Card.Title>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="setting-item">
                                <Row className="g-2 align-items-center">
                                    <Col md="5">
                                        <h6>Billing Contact Name</h6>
                                        <p>Required</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder="e.g. John Smith"
                                            value={contactName}
                                            onChange={handleContactNameChange}
                                            isInvalid={!emptyFieldsValidation && !isContactNameValid}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid name (latin letters only).
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2 align-items-center">
                                    <Col md="5">
                                        <h6>Company Name</h6>
                                        <p>Required</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            type="text"
                                            placeholder="e.g. Revbox"
                                            isInvalid={!emptyFieldsValidation && !companyName}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter company name.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2 align-items-center">
                                    <Col md="5">
                                        <h6>Billing Address</h6>
                                        <p>Required</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            className="mb-2"
                                            type="text"
                                            placeholder="Line 1 - required"
                                            onChange={(e) => setBillingAddress({ ...billingAddress, line1: e.target.value })}
                                            isInvalid={!emptyFieldsValidation && !billingAddress.line1}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter address.
                                        </Form.Control.Feedback>
                                        <Form.Control
                                            className="mb-2"
                                            type="text"
                                            placeholder="Line 2 - optional"
                                            onChange={(e) => setBillingAddress({ ...billingAddress, line2: e.target.value })}
                                        />
                                        <Row className="g-2 align-items-center">
                                            <Col md="6">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Post Code"
                                                    onChange={(e) => setBillingAddress({ ...billingAddress, postCode: e.target.value })}
                                                    isInvalid={!emptyFieldsValidation && !billingAddress.postCode}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter post code.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Town"
                                                    isInvalid={!emptyFieldsValidation && !billingAddress.town}
                                                    onChange={(e) => setBillingAddress({ ...billingAddress, town: e.target.value })}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter town.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6">
                                                { countries && countries.length > 0 &&
                                                    <Select
                                                        className="country-selector"
                                                        options={countries.map((country) => {
                                                            return { value: country.code, label: country.name };
                                                        })}
                                                        onChange={(e) => {
                                                            setCountry({ name: e.label, code: e.value });
                                                        }}
                                                        placeholder="Select Country"
                                                    />
                                                }
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose country.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Region"
                                                    onChange={(e) => setBillingAddress({ ...billingAddress, region: e.target.value })}
                                                    isInvalid={!emptyFieldsValidation && !billingAddress.region}
                                                    required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter region.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2 align-items-center">
                                    <Col md="5">
                                        <h6>
                                            {
                                                publisherCountry.code && countries.find((c) => c.code === publisherCountry?.code).eu ? "VAT" : "TAX"
                                            } Number
                                        </h6>
                                        <p>Optional</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder="e.g. GB123456789"
                                            onChange={(e) => { setVatNumber(e.target.value); }}
                                            isInvalid={!isVatNumberValid && vatNumber}
                                            value={vatNumber}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {
                                                publisherCountry.code && countries.find((c) => c.code === publisherCountry?.code).eu ? "VAT" : "TAX"
                                            } Number is invalid.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2 align-items-center">
                                    <Col md="5">
                                        <h6>Billing CC Email</h6>
                                        <p>Optional - Invoices will be sent to all admin and finance users, as well as this CC email address.</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter email address"
                                            onChange={handleEmailChange}
                                            isInvalid={!emptyFieldsValidation && !isEmailValid}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email address.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                    {clientSecret && ((billingMethod == CreditCardTopUp) || (billingMethod == CreditCardPostPayment)) && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm
                                dpmCheckerLink={dpmCheckerLink}
                                submitForm={submitForm}
                                formValues={formValues}
                                isVatNumberValid={isVatNumberValid}
                                setIsVatNumberValid={setIsVatNumberValid}
                                checkFormValues={checkFormValues}
                                setEmptyFieldsValidation={setEmptyFieldsValidation}
                                isEmailValid={isEmailValid}
                                clientSecret={clientSecret}
                                isContactNameValid={isContactNameValid}
                                billingMethod={billingMethod}
                                companyName={companyName}
                                customerId={customerId}
                                contactName={contactName}
                                billingAddress={billingAddress}
                                vatNumber={vatNumber}
                                countries={countries}
                                country={country}
                                campaignId={campaignId}
                                budget={budget}
                            />
                        </Elements>
                    )}
                    {((billingMethod == InvoiceTopUp) || (billingMethod == InvoicePostPayment)) && (
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="primary" onClick={submitForm}>Create Billing Method</Button>
                        </div>
                    )} 
                </Col>
            </div>
        </React.Fragment>
    )
}