import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { logosModule } from "../modules/logos";
import { CookiesKeyNames, getPathKey, useCookiesContext } from "../contexts/CookiesContext";
import { isValidEmail } from "../helpers/email";
import { forgotPassword } from "../services/micro";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { cookiesData, getPathKey } = useCookiesContext();
  const navigate = useNavigate();

  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode];
  const urlParams = new URLSearchParams(window.location.search);
  const pubParam = urlParams.get('pub');

  const publisherIdByAbbr = Number(pubParam);
  
  const [siteLogo, setSiteLogo] = useState(null);

  useEffect(() => {
    setSiteLogo(logosModule(publisherIdByAbbr, currentSkin));
  }, [publisherIdByAbbr, currentSkin]);

  const resetPassword = async () => {
   if (!email) {
    toast.error('Please enter your email address');
    return;
   }
   
   if (!isValidEmail(email)) {
    toast.error('Please enter a valid email address');
    return;
   }

   // send request to reset password
   const response = await forgotPassword(email);
   
   if (response?.success) {
    toast.success('Password reset email sent');
    setEmail("");
    navigate("/pages/signin");
   } else {
    toast.error('Failed to reset password');
   }
  }

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header className="pb-3">
          <Link to="/" className="header-logo">
            {siteLogo}
          </Link>
          <Card.Title>Forgot Password</Card.Title>
          <Card.Text>Please enter your email address to reset your password.</Card.Text>
        </Card.Header>
        <Card.Body className="pb-3 pt-3">
          <div className="mb-4">
            <Form.Label className="d-flex justify-content-between">Email</Form.Label>
            <Form.Control type="email" placeholder="Enter your email" onChange={e => setEmail(e.target.value)} />
          </div>
          <Button variant="primary" className="btn-sign" onClick={resetPassword}>Reset Password</Button> 
        </Card.Body>
        <Card.Footer>
          Not you? <Link to="/pages/signin">Sign in with different account</Link>
        </Card.Footer>
      </Card>
    </div>
  )
}