import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function StatusRenderer({ value }) {
  if (!value) {
    return null;
  }
    const statusMap = {
      PENDING: "badge bg-secondary w-100",
      LIVE: "badge bg-success w-100",
      INACTIVE: "badge bg-danger w-100",
      PAUSED: "badge bg-warning w-100",
      INHERITED: "badge text-success w-100",
      "BID ACTIVE": "badge bg-success w-100",
    };

    let status = value.split("@")[0].toUpperCase();
    let views = Number(value.split("@")[1]);
    let className = statusMap[status];

    // if (status === "LIVE") {
    //   status = "Bid Active".toUpperCase();
    // } 

    // if (views === 0) {
    //   status = "Inherited".toUpperCase();
    //   className = "badge bg-warning w-100";
    // }
    

    return className ? (
      <span className={className}>
        {status === "INHERITED" ? (
          <>
            <i className='ri-arrow-right-down-line'></i> {status.toUpperCase()}
          </>
        ) : (
          status.toUpperCase()
        )}
      </span>
    ) : (
      <span className="badge bg-secondary w-100">PENDING</span>
    );
}

export function StatusColumnHeaderTooltipRenderer() {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  let tooltip = taxonomy.tooltips.ppc_cluster_column_headers.status;

  return tooltip;
}

export function StatusCellTooltipRenderer({ value }) {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  if (!value) {
    return null;
  }
  
  let status = value.split("@")[0].toUpperCase();

  if (status === "LIVE") {
    return taxonomy.tooltips.ppc_cluster_column_inlines.status.live;
  }

  if (status === "INACTIVE") {
    return taxonomy.tooltips.ppc_cluster_column_inlines.status.inactive;
  }

  if (status === "PAUSED") {
    return taxonomy.tooltips.ppc_cluster_column_inlines.status.paused;
  }

  if (status === "PENDING") {
    return taxonomy.tooltips.ppc_cluster_column_inlines.status.pending;
  }

  if (status === "INHERITED") {
    return taxonomy.tooltips.ppc_cluster_column_inlines.status.inherited;
  }

  return "Your status is " + status;
}

  