import axios from "axios";

async function forgotPassword(email) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_HUB_URL + "/api/auth/forgot-password",
      {
        email: email
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export default forgotPassword;
