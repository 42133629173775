import axios from 'axios'

async function validateDisposableEmail(email) {
    try {
        const response = await axios.get(process.env.REACT_APP_HUB_URL + `/api/emails/validate-disposable-email/?email=${email}`)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export default validateDisposableEmail
